@charset "utf-8";
/*======================================
 [ -Main Stylesheet-
  Theme: CityBook
  Version: 1.0
  Last change: 02/02/2018 ]
++ Fonts ++
++ General Style ++
++ Typography ++
++ loader ++
++ Content Styles ++
++ Header ++
++ Navigation ++
++ section ++
++ Home / Sliders ++
++ Register form ++
++ Listing ++
++ Scroll nav ++
++ Forms ++
++ Counter ++
++ Team ++
++ Map ++
++ Accordion ++
++ Dashboard ++
++ Button ++
++ Grid Gallery ++
++ Image collage ++
++ Price tables ++
++ Testimonials ++
++ Sponsors ++
++ Footer ++
++ Timeline ++
++ Blog ++
++ Video ++
++ Share ++
++ 404 ++
++ Coming Soon ++
++ Responsive ++
/*-------------Fonts---------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,800i,900|Quicksand:300,400,500,700&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese');

@font-face {
    font-family: 'AvantGarde';
    src: url('./fonts/AvantGarde.otf');
}

/*-------------General Style---------------------------------------*/
html {
    overflow-x: hidden !important;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    height: 100%;
    text-align: center;
    background: #1A1F3E;
    color: #000;
    font-family: 'AvantGarde';
}

@-o-viewport {
    width: device-width;
}

@-ms-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

/*--------------Typography--------------------------------------*/
p {
    font-size: 12px;
    line-height: 24px;
    padding-bottom: 10px;
    font-weight: 500;
    color: #666;
}

blockquote {
    float: left;
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 15px solid #eee;
    position: relative;
}

blockquote:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f10e";
    position: absolute;
    color: #ccc;
    bottom: 3px;
    font-size: 43px;
    right: 6px;
    opacity: 0.4
}

blockquote p {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    color: #494949;
}

/*------ loader-------------------------------------------------*/
.loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #1A1F3E;
    -webkit-transform: translate3d(0, 0, 0);
}

.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #89849b;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.pin:after {
    content: '';
    width: 14px;
    height: 14px;
    top: 8px;
    left: 8px;
    background: #2F3B59;
    position: absolute;
    border-radius: 50%;
}

.pulse {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -19px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    box-shadow: 0 0 1px 3px #4DB7FE;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

/* ---------Content Styles--------------------------------------*/
#main {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 1;
}

.container {
    max-width: 1224px;
    width: 92%;
    margin: 0 auto;
    position: relative;
    z-index: 5;
}

.big-container {
    max-width: 1500px;
}

.small-container {
    max-width: 1024px;
}

#wrapper {
    float: left;
    width: 100%;
    position: relative;
    z-index: 5;
    padding-top: 80px;
}

#wrapper.no-padding {
    padding-top: 0;
}

.content {
    width: 100%;
    position: relative;
    z-index: 4;
    float: left;
    background: #fff;
}

.content.fs-slider-wrap {
    padding-top: 80px;
}

.fl-wrap {
    float: left;
    width: 100%;
    position: relative;
}

.no-bg {
    background: none;
}

.full-height {
    height: 100%;
}

.full-width-wrap {
    width: 100%;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-repeat: repeat;
    background-origin: content-box;
}

.shapes-bg-small:before, .shapes-bg-big:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    opacity: 0.3;
}

.respimg {
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 2
}

.box-item {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.box-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    background: rgba(51, 51, 51, 0.41);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.box-item a.gal-link {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    color: #fff;
    z-index: 10;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.fw-separator {
    float: left;
    width: 100%;
    margin: 25px 0;
    height: 1px;
    background: #eee;
}

.ad .react-parallax-bgimage {
    object-fit: cover;
    position: absolute;
    bottom: -250px;
}

/* ------Header------------------------------------------------------------ */
.main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    padding: 0 0 6px 0;
    -webkit-transform: translate3d(0, 0, 0);
    height: 80px;
}

.center-header .header-inner {
    max-width: 1224px;
    margin: 0 auto;
    position: relative;
}

.center-header .header-inner .show-search-button {
    display: block;
}

.center-header .header-search {
    display: none;
    position: absolute;
    left: 20px;
    top: 100px;
    border-radius: 30px;
    box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.2);
}

.dark-header {
    background: #1A1F3E;
}

.fs-header {
    padding: 0px 50px;
}

.logo-holder {
    float: left;
    position: relative;
    top: 25px;
    height: 30px;
}

.logo-holder img {
    height: 120%;
    width: auto;
}

.add-list {
    float: right;
    padding: 13px 32px;
    position: relative;
    top: 20px;
    border-radius: 30px;
    color: #fff;
    font-weight: 700;
    font-size: 11px;
}

.add-list span {
    padding-left: 4px;
    font-weight: 700;
}

.dark-header .add-list {
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.dark-header .add-list:hover {
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.1);
}

.show-reg-form {
    float: right;
    color: #666;
    cursor: pointer;
    margin-right: 40px;
    position: relative;
    top: 34px;
    font-size: 13px;
    font-weight: 600;
}

.dark-header .show-reg-form {
    color: #fff;
}

.show-reg-form:before {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    height: 30px;
    width: 1px;
    margin-top: -15px;
    background: rgba(255, 255, 255, 0.41);
}

.dark-header .show-reg-form:before {
    color: #fff;
    background: rgba(255, 255, 255, 0.21);
}

.show-reg-form i {
    padding-right: 10px;
}

.vis-header-search {
    float: left;
    margin-left: 30px;
    position: relative;
    top: 20px;
}

.header-search-input-item input {
    border: none;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    float: left;
    width: 130px;
}

.header-search-input-item {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    background: #fff;
}

.header-search-select-item {
    background: #fff;
    height: 40px;
    border-left: 1px solid #eee;
}

.header-search-select-item .nice-select {
    color: #292929;
    float: left;
    width: 100%;
    border: none;
    min-width: 150px;
    height: 40px;
    line-height: 40px;
}

.header-search-select-item .nice-select .list {
    width: 100%;
    padding: 60px 12px 10px;
    background: #fff;
    border-radius: 4px;
}

.header-search-select-item .nice-select .list li {
    float: left;
    width: 100%;
    padding: 5px 10px 6px;
    color: #666;
    font-size: 13px;
    border-radius: 2px;
    min-height: 20px;
    line-height: 20px;
}

.header-search-select-item .nice-select .nice-select-search {
    border: 1px solid #eee;
}

.header-search-select-item .nice-select .list li:hover, .header-search-select-item .nice-select .list li.selected {
    color: #fff;
}

.header-search-input-item, .header-search-select-item, .header-search-button {
    float: left;
}

.header-search-button {
    height: 40px;
    border: none;
    color: #fff;
    line-height: 40px;
    border-radius: none;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 0 30px;
    cursor: pointer;
}

.show-search-button {
    display: none;
    color: #fff;
    float: left;
    position: relative;
    margin-left: 20px;
    top: 32px;
    cursor: pointer;
    font-weight: 600;
    padding-left: 18px;
}

.show-search-button:before {
    content: '';
    position: absolute;
    left: 0;
    top: -6px;
    bottom: -6px;
    width: 1px;
    background: rgba(255, 255, 255, 0.21);
}

.show-search-button i {
    padding-right: 8px;
}

.header-user-menu {
    float: right;
    position: relative;
    top: 20px;
    margin-left: 50px;
    margin-right: 40px;
}

.header-user-menu:after {
    content: '';
    position: absolute;
    left: -70px;
    height: 30px;
    top: 4px;
    width: 1px;
    background: rgba(255, 255, 255, 0.21);
}

.header-user-name {
    position: relative;
    float: left;
    cursor: pointer;
    color: #fff;
    transition: 0.2s;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 10px;
    font-weight: 600;
}

.header-user-name:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f0d7";
    position: absolute;
    color: #fff;
    bottom: 1px;
    font-size: 13px;
    right: -16px;
    transition: all .2s ease-in-out;
}

.header-user-name span {
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    overflow: hidden;
    top: 0;
    left: -50px;
}

.header-user-name span img {
    width: 100%;
    height: 100%;
}

.header-user-menu ul {
    margin: 10px 0 0 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    min-width: 150px;
    top: 60px;
    left: -50px;
    z-index: 1;
    padding: 10px 0;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
    transition: all .2s ease-in-out;
}

.header-user-name.hu-menu-visdec:before {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.header-user-menu ul.hu-menu-vis {
    opacity: 1;
    visibility: visible;
    top: 40px;
}

.header-user-menu ul li {
    float: left;
    width: 100%;
    padding: 4px 0;
}

.header-user-menu ul li a {
    color: #50596E;
    float: left;
    width: 100%;
    font-weight: 500;
    text-align: left;
    padding: 6px 15px;
}

/* ------Navigation------------------------------------------------------------ */
.nav-holder {
    float: right;
    position: relative;
    top: 18px;
    margin-right: 30px;
    opacity: 1;
    right: 0;
    visibility: visible;
}

.nav-holder nav {
    position: relative;
    float: right;
}

.nav-holder nav li {
    float: left;
    position: relative;
    margin-left: 6px;
    height: 70px;
}

.nav-holder nav li ul {
    margin: 30px 0 0 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    min-width: 150px;
    top: 50px;
    left: 0;
    z-index: 1;
    padding: 10px 0;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
    transition: all .2s ease-in-out;
}

.nav-holder nav li:hover > ul {
    opacity: 1;
    visibility: visible;
    margin: 0;
}

.nav-holder nav li ul li ul {
    top: -10px;
    left: -100%;
    margin-left: 25px;
    margin-top: 0;
    max-width: 150px;
}

.nav-holder nav li ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    margin-right: 0px;
}

.nav-holder nav li ul li {
    width: 100%;
    float: left;
    height: auto;
    position: relative;
}

.nav-holder nav li a {
    float: left;
    padding: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    text-transform: none;
    line-height: 25px;
    letter-spacing: 0px;
    color: #494949;
    -webkit-transition: all 100ms linear;
    transition: all 100ms linear;
}

.dark-header .nav-holder nav li a {
    color: #fff;
}

.nav-holder nav li a:before {
    content: '';
    position: absolute;
    bottom: -19px;
    left: 0;
    right: 0;
    height: 4px;
    border-radius: 4px;
    display: none;
}

.nav-holder nav li a.act-link:before {
    display: block;
}

.nav-holder nav li a i {
    padding-left: 6px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.nav-holder nav li a:hover i {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.nav-holder nav li ul a {
    color: #566985;
    float: left;
    width: 100%;
    font-weight: 500;
    text-align: left;
    padding: 5px 15px;
}

.dark-header .nav-holder nav li ul a {
    color: #000;
}

nav li ul a:before, nav li ul a:after {
    display: none;
}

.nav-button-wrap {
    float: right;
    height: 38px;
    width: 38px;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    top: 24px;
    margin-right: 16px;
    display: none;
}

.nav-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 20px;
    margin-left: -11px;
    margin-top: -6px;
}

.nav-button span {
    float: left;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-bottom: 4px;
    border-radius: 4px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

/*------ section-------------------------------------------------*/
section {
    float: left;
    width: 100%;
    position: relative;
    padding: 50px 0;
    overflow: hidden;
    background: #fff;
    z-index: 1;
}

section.no-top-padding {
    padding: 50px 0;
}

section.no-pading {
    padding-bottom: 0;
}

.header-sec-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 20;
}

.header-sec-link a {
    display: inline-table;
    padding: 15px 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: #EC8500;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
}

section.color-bg .header-sec-link a {
    color: #000;
    background: #fff !important;
}

section.color-bg .header-sec-link a:hover {
    color: #fff;
}

.header-sec-link a:hover {
    color: #666;
    background: #fff;
}

div.hero-section {
    height: 120%;
    top: -10%;
}

section.hero-section {
    padding: 150px 0 200px;
}

section.hero-section.no-dadding {
    padding: 0;
}

section.gray-section {
    background: #f9f9f9;
}

section.hero-section .bg {
    height: 120%;
    top: -10%;
}

section.parallax-section {
    padding: 110px 0;
}

section.video-section {
    padding: 150px 0;
}

section.single-par {
    padding: 180px 0;
}

section.parallax-section.small-par {
    padding: 90px 0;
}

.hero-section-wrap {
    z-index: 30;
}

section.parallax-section .bg {
    height: 120%;
    top: 0;
}

.section-title {
    float: left;
    width: 100%;
    position: relative;
}

.section-title .breadcrumbs {
    margin-top: 20px;
}

.section-title .breadcrumbs a, .section-title .breadcrumbs span {
    color: rgba(255, 255, 255, 0.71);
    display: inline-block;
    padding: 0 16px;
    position: relative;
    font-weight: 500;
}

.section-title .breadcrumbs span, .section-title .breadcrumbs a:hover {
    color: #fff;
}

.section-title .breadcrumbs a:before {
    content: '';
    position: absolute;
    top: 50%;
    right: -4px;
    margin-top: -3px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
}

section.color-bg .section-title .breadcrumbs {
    margin-top: 0;
    margin-bottom: 10px;
}

section.color-bg .section-title .breadcrumbs a:before {
    background: #fff !important;
}

.section-title p {
    color: #666;
    font-size: 13px;
    text-align: left;
    margin-bottom: 50px;
}

.section-title h2 {
    float: left;
    width: 100%;
    text-align: center;
    color: #1A1F3E;
    font-size: 34px;
    font-weight: 700;
}

.section-title-white h2 {
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 34px;
    font-weight: 700;
}
.hero-section .intro-item h2 {
    font-size: 54px;
}

.parallax-section .section-title h2 {
    font-size: 44px;
}

.react-parallax-content .section-title h2 {
    font-size: 44px;
}

.section-separator {
    float: left;
    width: 100%;
    position: relative;
    margin: 20px 0 50px;
}

section.gray-section .section-separator {
    background: #f9f9f9;
}

.section-separator:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    height: 4px;
    margin-left: -25px;
    width: 50px;
    border-radius: 3px;
}

.section-separator.fl-sec-sep:before {
    left: 0;
    margin-left: 0;
}

.parallax-section .section-title h2, .parallax-section .section-title p {
    color: #fff;
}

.react-parallax-content .section-title h2, .parallax-section .section-title p {
    color: #fff;
}

.parallax-section .section-title.left-align, .parallax-section .section-title.left-align h2 {
    text-align: left;
}

.section-subtitle {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    top: -20px;
    font-size: 64px;
    opacity: 0.13;
    z-index: -1;
    text-transform: uppercase;
    font-weight: bold;
}

.parallax-section .section-subtitle {
    color: #fff;
    opacity: 0.2;
}

.section-subtitle:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 255)), color-stop(100%, rgba(255, 255, 255, 0.75)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fffff', GradientType=0);
}

.gray-section .section-subtitle:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(249, 249, 249, 249)), color-stop(100%, rgba(249, 249, 249, 0.75)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(249, 249, 249, 0.75) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(249, 249, 249, 0.75) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(249, 249, 249, 0.75) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(249, 249, 249, 0.75) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fffff', GradientType=0);
}

.parallax-section .section-subtitle:before {
    display: none;
}

/*------ Home / Sliders-------------------------------------------------*/
.intro-item h2 {
    font-size: 34px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 20px;
}

.intro-item h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.main-search-input {
    background: #fff;
    padding: 0 120px 0 0;
    border-radius: 30px;
    margin-top: 50px;
}

.main-search-input-item {
    float: left;
    width: 33.3%;
    box-sizing: border-box;
    border-right: 1px solid #eee;
    height: 50px;
    position: relative;
}

.main-search-input-item input {
    float: left;
    border: none;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    font-size: 13px;
}

.main-search-input-item input:first-child {
    border-radius: 100%;
}

.main-search-button {
    position: absolute;
    right: 0px;
    height: 50px;
    width: 120px;
    color: #fff;
    top: 0;
    border: none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    cursor: pointer;
}

.main-search-input-item .nice-select {
    color: #292929;
    float: left;
    width: 100%;
    border: none;
    min-width: 150px;
    height: 50px;
    z-index: 100;
    line-height: 50px;
}

.main-search-input-item .nice-select .list {
    width: 100%;
    padding: 60px 12px 10px;
    background: #fff;
    border-radius: 4px;
    max-height: 180px;

}

.main-search-input-item .nice-select .list li {
    float: left;
    width: 100%;
    padding: 5px 10px 6px;
    color: #666;
    font-size: 13px;
    border-radius: 2px;
    min-height: 20px;
    line-height: 20px;
}

.main-search-input-item .nice-select .nice-select-search {
    border: 1px solid #eee;
    border-radius: 6px !important;
}

.main-search-input-item .nice-select .list li:hover, .main-search-input-item .nice-select .list li.selected {
    color: #fff;
}

.main-search-input-wrap {
    max-width: 900px;
    margin: 20px auto;
    position: relative;
}

.main-search-input:before {
    content: '';
    position: absolute;
    bottom: -40px;
    width: 50px;
    height: 1px;
    background: rgba(255, 255, 255, 0.41);
    left: 50%;
    margin-left: -25px;
}

.main-search-input-item.location a {
    position: absolute;
    top: 18px;
    right: 22px;
    z-index: 500
}

.main-search-input-item.location a:hover {
    border-radius: 100%;
    animation: pulse 1.1s infinite ease-in-out;
}

.slider-container-wrap {
    padding: 300px 0;
}

.slider-container-wrap.fs-slider {
    padding: 0;
}

.slider-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider-container .hero-section-wrap {
    padding-top: 150px;
}

.slider-container-wrap.fs-slider .hero-section-wrap {
    top: 35%;
    padding-top: 0;
}

.bubble-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.individual-bubble {
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    background-color: #ccc;
    z-index: 1;
    width: 5px !important;
    height: 5px !important;
    background: #fff;
    opacity: 0.3;
    -webkit-animation: fade 20s infinite;
}

@-webkit-keyframes fade {
    from {
        bottom: -24px;
        opacity: 0.5;
        visibility: visible
    }
    to {
        bottom: 120%
    }
}

@keyframes fade {
    from {
        bottom: -24px;
        opacity: 0.5;
        visibility: visible
    }
    to {
        bottom: 120%
    }
}

.absolute-main-search-input {
    position: absolute;
    bottom: 70px;
    left: 50%;
    margin-left: -450px;
    width: 900px;
    z-index: 10;
}

.absolute-main-search-input .container {
    max-width: 900px;
}

.absolute-main-search-input .main-search-input {
    box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.slideshow-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    z-index: 1;
}

.slideshow-item, .slideshow-container .slick-slide {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.sw-btn {
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 100%;
    color: #fff;
    z-index: 120;
    cursor: pointer;
    margin-top: -22px;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
}

.sw-btn.swiper-button-prev {
    left: 50px;
}

.sw-btn.swiper-button-next {
    right: 50px;
}

.sw-btn:hover {
    background: #2C3B5A;
}

.single-carousel .slick-dots, .listing-carousel .slick-dots {
    bottom: -50px;
}

.single-carousel .slick-dots li button, .listing-carousel .slick-dots li button {
    background: #BDCBDB;
}

.list-single-carousel-wrap {
    height: 500px;
    background: #24324F;
}

.fw-carousel .slick-slide-item {
    width: auto;
    height: 500px !important;
    float: left;
    cursor: w-resize;
}

.fw-carousel .slick-slide-item img {
    width: auto;
    height: 100% !important;
}

.list-single-carousel-wrap .slick-slide-item .box-item {
    height: 100%;
    z-index: 20;
}

.list-single-carousel-wrap .slick-slide-item .box-item:before {
    display: none;
}

.list-single-carousel-wrap .slick-slide-item {
    float: left;
    width: auto;
    height: 100%;
    padding: 0 0px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
}

.list-single-carousel-wrap .swiper-slide:first-child {
    padding-left: 0px;
}

.list-single-carousel-wrap .slick-slide-item .box-item a.gal-link {
    top: -100px;
    right: 50px;
}

.list-single-carousel-wrap .slick-slide-item .box-item:hover a.gal-link {
    top: 50px;
}

.list-single-carousel-wrap .sw-btn, .single-slider-wrapper .sw-btn {
    top: 50%;
}

.list-carousel .sw-btn.swiper-button-prev {
    left: 8px;
}

.list-carousel .sw-btn.swiper-button-next {
    right: 8px;
}

.single-slider img {
    width: 100%;
    height: auto;
}

.slick-dots {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 6px;
    height: 6px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: rgba(255, 255, 255, 0.51);
    border-radius: 100%;
}

.box-cat-container {
    display: inline-table;
    margin-top: 10px;
}

.box-cat {
    display: inline-block;
    padding: 20px 0;
    border-radius: 6px;
    color: #fff;
    margin: 0 10px;
    min-width: 120px;
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}

.box-cat:hover {
    background: #2C3B5A;
}

.box-cat i {
    padding-bottom: 10px;
    font-size: 16px;
}

.bg-ser {
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.popular-searching {
    float: left;
    width: 100%;
    margin-top: 40px;
}

.popular-searching div, .popular-searching a {
    display: inline-block;

    margin-right: 20px;
    font-weight: 500;
}

.popular-searching div {
    color: #fff;
    margin-bottom: 20px;
    font-size: 13px;
}

.popular-searching a {
    color: rgba(255, 255, 255, 0.81);
    font-size: 13px;
}

.popular-searching a:hover {
    color: #fff;
}

/*------ register form------------------------------------------------*/
.main-register-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: auto;
    display: none;
    -webkit-transform: translate3d(0, 0, 0);
    background: rgba(0, 0, 0, 0.81);
}

.hid-body {
    overflow: hidden;
}

.main-register-holder {
    max-width: 480px;
    margin: 100px auto 50px;
    position: relative;
    z-index: 5;
}

.main-register h3 {
    float: left;
    width: 100%;
    text-align: left;
    font-size: 20px;
    padding: 20px 30px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #666;
    border-bottom: 1px solid #eee;
}

.main-register h3 span {
    font-weight: 800;
    font-size: 18px;
    color: #566985;
    text-transform: uppercase;
    font-family: 'AvantGarde', sans-serif;
}

.main-register {
    float: left;
    width: 100%;
    position: relative;
    padding: 0 0 30px;
    margin-bottom: 50px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.12);
}

.close-reg {
    position: absolute;
    top: 14px;
    right: 30px;
    border-radius: 100%;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.soc-log {
    padding: 15px 30px;
}

.soc-log p {
    margin-bottom: 12px;
    font-size: 13px;
    color: #454545;
}

.soc-log a {
    float: left;
    width: 100%;
    padding: 13px 0;
    color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
}

.soc-log a i {
    font-size: 20px;
    padding-right: 15px;
    position: relative;
    top: 3px;
}

.soc-log a.facebook-log {
    background: #3b5998;
}

.soc-log a.twitter-log {
    background: #55acee;
}

.close-reg:hover, .custom-form .log-submit-btn:hover, .soc-log a:hover {
    background: #2F3B59;
}

.log-separator span {
    position: relative;
    width: 90px;
    display: inline-block;
    background: #fff;
    z-index: 2;
}

.log-separator:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px;
    height: 1px;
    background: #eee;
    z-index: 1;
}

.custom-form .log-submit-btn {
    float: left;
    padding: 13px 35px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 12px;
}

.main-register .filter-tags {
    float: left;
}

.lost_password {
    margin-top: 36px;
    float: right;
}

.lost_password a {
    float: left;
    color: #50596E;
    font-size: 12px;
    font-weight: 600;
}

#tabs-container {
    float: left;
    width: 100%;
    margin-top: 20px;
    padding: 0 30px;
}

.tab {
    float: left;
    width: 100%;
}

.tabs-menu {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
}

.tabs-menu li {
    float: left;
    margin-right: 20px;
}

.tabs-menu li a {
    float: left;
    padding: 14px 35px;
    font-weight: 500;
    color: #494949;
    background: #eee;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.tabs-menu li.current a, .tabs-menu li a:hover {
    color: #fff;
}

.tab-content {
    width: 100%;
    padding: 20px 0;
    display: none;
}

#tab-1 {
    display: block;
}

/*------ listing------------------------------------------------*/
.card-listing .geodir-category-listing {
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #eee;
}

.card-listing .geodir-category-content {
    z-index: 2;
}

.card-listing .geodir-category-content h3 {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}

.card-listing .geodir-category-content h3 a {
    color: #1A1F3E;
}

.card-listing .geodir-category-content p {
    text-align: left;
    font-size: 12px;
    color: #666;
}

.card-listing .geodir-category-img img {
    float: left;
    width: 100%;
    height: 250px;
    object-fit: cover;
    z-index: 1;
}

.card-listing .geodir-category-img .overlay {
    opacity: 0.1;
}

.card-listing .geodir-category-content {
    padding: 30px 20px 10px;
    background: #fff;
}

.card-listing .listing-rating {
    float: left;
    width: 100%;
    margin-top: 20px;
}

.card-listing .listing-rating.card-popup-rainingvis i, .dashboard-listing-table-text .listing-rating.card-popup-rainingvis i {
    float: left;
    margin-right: 7px;
}

.card-listing .listing-rating span, .dashboard-listing-table-text .listing-rating span {
    float: left;
    margin-left: 10px;
    color: #999;
    position: relative;
    top: -2px;
}

.card-listing .listing-avatar {
    position: absolute;
    top: -30px;
    right: 20px;
    width: 60px;
    height: 60px;
    z-index: 10;
}

.card-listing .listing-avatar img {
    width: 60px;
    height: 60px;
    border: 6px solid rgba(255, 255, 255, 0.4);
    border-radius: 100%;
}

.avatar-tooltip {
    position: absolute;
    top: -50px;
    padding: 15px 15px 15px 4px;
    border-radius: 4px;
    text-align: right;
    background: #fff;
    z-index: 10;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    margin-top: -20px;
    min-width: 200px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.avatar-tooltip strong {
    font-weight: 700;
    color: #666;
}

.avatar-tooltip:after {
    top: 100%;
    right: 22px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.avatar-tooltip:after {
    border-color: transparent;
    border-top-color: #fff;
    border-width: 7px;
    margin-left: 7px;
}

.card-listing .listing-avatar:hover .avatar-tooltip {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
}

.card-listing .geodir-category-location i, .card-listing .geodir-category-location a {
    float: left;
}

.card-listing .geodir-category-location {
    padding: 15px 0;
    float: left;
    width: 100%;
    margin-top: 15px;
    font-weight: 500;
    border-top: 1px solid #eee;
}

.card-listing .geodir-category-location a {
    color: #999;
    position: relative;
}

.card-listing .geodir-category-location a.map-item:before {
    content: "On The Map";
    position: absolute;
    right: -90px;
    top: 0;
    transition: all .2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    color: #5ECFB1;
}

.card-listing .geodir-category-location a.map-item:hover:before {
    visibility: visible;
    opacity: 1;
}

.card-listing .geodir-category-location i {
    padding-right: 15px;
    font-size: 14px;
}

.card-listing .list-post-counter {
    position: absolute;
    z-index: 4;
    right: 20px;
    top: 20px;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    padding: 6px 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.41);
}

.card-listing .list-post-counter i {
    padding-left: 10px;
}

.card-listing .listing-geodir-category {
    position: absolute;
    top: -20px;
    left: 20px;
    padding: 10px 25px;
    color: #fff;
    border-radius: 4px;
    z-index: 5;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
}

.card-btn {
    float: left;
    margin-top: 20px;
    padding: 15px 40px;
    border-radius: 6px;
    background: #f9f9f9;
    font-weight: 500;
    color: #fff;
}

.card-btn i {
    padding-left: 10px;
}

.card-btn:hover {
    color: #fff;
}

.listing-item-grid {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}

.listing-item-grid:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.31);
    z-index: 2;
    transition: all .2s ease-in-out;
}

.listing-item-grid:hover:before {
    background: rgba(0, 0, 0, 0.41);
}

.listing-item-grid .listing-item-cat {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px 10px 20px;
}

.listing-item-grid .listing-item-cat h3 {
    float: left;
    text-align: left;
    font-size: 20px;
    font-weight: 700;
}

.listing-item-grid .listing-item-cat h3 a {
    color: #fff;
}

.listing-item-grid .listing-item-cat h3 a:hover {
    color: rgba(255, 255, 255, 0.71);
}

.listing-item-grid .listing-item-cat p {
    float: left;
    width: 100%;
    margin-top: 10px;
    color: #fff;
    font-size: 13px;
    text-align: left;
}

.listing-item-grid .listing-counter {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 8px 16px;
    z-index: 3;
    color: #fff;
    border-radius: 20px;
    font-size: 11px;
    font-weight: 600;
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.3);
}

.listing-item-grid img, .geodir-category-img img {
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
}

.listing-item-grid:hover img, .geodir-category-listing:hover .geodir-category-img img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}

.col-list-wrap {
    position: relative;
    width: 50%;
    background: #f9f9f9;
    min-height: 10vh;
    z-index: 10;
}

.gray-bg {
    background: #f9f9f9;
}

.fh-col-list-wrap, .center-col-list-wrap {
    width: 100%;
}

.left-list {
    float: left;
}

.right-list {
    float: right;
}

.load-more-button {
    color: #fff;
    display: inline-block;
    padding: 15px 35px;
    border-radius: 30px;
    margin-top: 50px;
    position: relative;
    box-shadow: 0px 0px 0px 7px rgba(204, 204, 204, 0.2);
}

.load-more-button i {
    float: right;
    margin-left: 10px;
    top: 2px;
    position: relative;
    animation-name: spin;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.back-to-filters {
    position: absolute;
    right: -40px;
    width: 40px;
    height: 40px;
    top: 0;
    color: #fff;
    line-height: 40px;
    z-index: -1;
}

.back-to-filters.btf-l {
    right: -40px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.back-to-filters.btf-r {
    left: -40px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.back-to-filters span {
    visibility: visible;
    position: absolute;
    height: 40px;
    min-width: 150px;
    line-height: 40px;
    visibility: hidden;
    opacity: 0;
    top: 0;
    margin-top: 20px;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    font-weight: 500;
}

.back-to-filters.btf-l span {
    left: -150px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.back-to-filters.btf-r span {
    right: -150px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.back-to-filters:hover span {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
}

.listsearch-options {
    padding: 50px 10px;
    background: #fff;
    z-index: 20;
}

.listing-view-layout {
    float: right;
}

.listing-view-layout li {
    float: left;
    margin-left: 10px;
}

.listing-view-layout li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    float: left;
    border: 1px solid #fff;
    border-radius: 100%;
    color: #fff;
}

.listing-view-layout li a i {
    font-size: 14px;
}

.listing-view-layout li a.active {
    background: #fff;
}

.listsearch-header {
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
}

.fh-col-list-wrap .listsearch-input-wrap {
    background: #fff;
    border: none;
    padding: 30px 30px;
    margin-top: 0;
    border-radius: 6px;
}

.listsearch-header h3 {
    float: left;
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    color: #5D6275;
}

.listsearch-input-wrap {
    margin-top: 30px;
}

.listsearch-input-text a.loc-act {
    position: absolute;
    top: 54px;
    right: 20px;
    z-index: 5;
    cursor: pointer;
}

@keyframes pulse {
    50% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, .1),
        0 0 0 20px rgba(255, 255, 255, .2),
        0 0 0 40px rgba(238, 238, 238, 0.3000);
    }
}

.listsearch-input-text a.loc-act:hover {
    border-radius: 100%;
    animation: pulse 1.1s infinite ease-in-out;
}

.listsearch-input-text input, .listsearch-input-item input {
    float: left;
    border: 1px solid #eee;
    background: #fff;
    background: #f9f9f9;
    width: 100%;
    padding: 15px 20px 15px 20px;
    border-radius: 6px;
    -webkit-appearance: none;
}

.listsearch-input-text label {
    float: left;
    position: relative;
    text-align: left;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
}

.listsearch-input-text label i {
    position: absolute;
    bottom: -34px;
    left: 12px;
    font-size: 22px;
}

.listsearch-input-text {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
}

.fh-col-list-wrap .listsearch-input-text {
    margin-bottom: 0;
}

.listsearch-input-item {
    float: left;
    width: 33.3%;
    padding: 0 3px;
    margin-bottom: 10px;
    position: relative;
}

.fh-col-list-wrap .listsearch-input-wrap .listsearch-input-item {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
}

.listsearch-input-item i.single-i {
    position: absolute;
    left: 22px;
    top: 16px;
    z-index: 10;
    font-size: 18px;
}

.listsearch-input-item .nice-select {
    color: #fff;
    border-radius: 4px;
    float: left;
    width: 100%;
}

.listsearch-input-item .nice-select .list {
    float: left;
    width: 100%;
    padding: 60px 12px 10px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
}

.listsearch-input-item .nice-select .list li {
    float: left;
    width: 100%;
    padding: 5px 10px 6px;
    color: #666;
    font-size: 13px;
    border-radius: 2px;
    min-height: 20px;
    line-height: 20px;
}

.listsearch-input-item .nice-select .list li:hover, .listsearch-input-item .nice-select .list li.selected {
    color: #fff;
}

.filter-tags {
    margin-top: 30px;
}

.fh-col-list-wrap .filter-tags {
    margin-bottom: 20px;
}

.filter-tags-wrap {
    float: left;
}

.fh-col-list-wrap .filter-tags-wrap {
    width: 100%;
    margin-bottom: 10px;
}

.filter-tags h4 {
    float: left;
    position: relative;
    text-align: left;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
}

.data-radius-title {
    font-style: normal;
    font-size: 15px;
    color: #888;
    font-weight: 400;
    margin-top: 0;
    display: block;
}

.distance-input {
    margin-top: 30px;
    margin-bottom: 50px;
}

.hidden-listing-filter {
    display: none;
    margin-bottom: 30px;
}

.fs-map-btn {
    border: none;
    float: right;
    margin-top: 30px;
    padding: 15px 45px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.fs-home-search-btn {
    border: none;
    float: right;
    margin-top: 1px;
    padding: 15px 45px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}


.more-filter-option {
    float: right;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    top: 10px;
}

.more-filter-option:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 26px;
    height: 1px;
    border-bottom: 1px dotted #ccc;
}

.more-filter-option span {
    float: right;
    width: 24px;
    height: 24px;
    position: relative;
    border-radius: 100%;
    margin-left: 10px;
}

.more-filter-option span:before, .more-filter-option span:after {
    content: '';
    position: absolute;
    background: #fff;
    border-radius: 4px;
    -webkit-transition: all 100ms linear;
    transition: all 100ms linear;
}

.more-filter-option span:before {
    top: 50%;
    left: 6px;
    width: 12px;
    height: 2px;
    margin-top: -1px;
}

.more-filter-option span:after {
    top: 6px;
    left: 50%;
    width: 2px;
    height: 12px;
    margin-left: -1px;
}

.more-filter-option span.mfilopact:after {
    height: 0px;
}

.listing-item {
    float: left;
    width: 50%;
    padding: 0 8px;
    margin-bottom: 12px;
}

.list-carousel {
    padding: 0 30px;
}

.list-carousel .listing-item {
    width: 100%;
}

.slick-slide-item {
    float: left;
    width: 100%;
}

.listing-item.list-layout {
    width: 100%;
    padding: 0;
    background: #fff;
}

.listing-item.list-layout .geodir-category-img, .listing-item.list-layout .geodir-category-content {
    width: 50%;
    float: left;
    position: relative;
}

.listing-item.list-layout .geodir-category-img {
    overflow: hidden;
}

.listing-item.list-layout .listing-geodir-category {
    left: -100%;
    margin-left: 20px;
    top: 20px;
    z-index: 100;
}

.listing-item.list-layout .listing-avatar {
    top: 100%;
    margin-top: -40px;

    margin-right: 20px;
    right: 100%;
}

.listsearch-maiwrap {
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.21);
    padding: 25px 30px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.center-col-list-wrap .listing-item {
    width: 33.3%;
    margin-bottom: 20px;
}

.center-col-list-wrap .listing-item.list-layout {
    width: 100%;
}

.center-col-list-wrap .listing-item.list-layout .geodir-category-img {
    width: 30%;
    float: left;
    position: relative;
}

.center-col-list-wrap .listing-item.list-layout .geodir-category-content {
    width: 70%;
    float: left;
    position: relative;
}

.list-main-wrap {
    padding: 50px 0;
}

.distance-title {
    float: left;
    text-align: left;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
}

.distance-title i {
    font-size: 14px;
    padding-right: 6px;
}

.distance-radius-wrap {
    height: 3px;
    border-radius: 4px;
    background: #BDCBDB;
}

.map-popup-wrap {
    display: block;
    width: 300px !important;
    position: relative;
}

.map-popup {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 9px 16px rgba(58, 87, 135, 0.15);
}

.map-popup-wrap:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-top: -1px;
    z-index: 20;
}

.map-popup-wrap:before {
    border-color: transparent;
    border-top-color: #fff;
    border-width: 15px;
    margin-left: -15px;
}

.map-popup img {
    width: 100%;
    height: auto;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
}

.listing-img-content:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}

.listing-content {
    background: #fff;
    padding: 25px 20px;
    z-index: 20;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.infoBox-close {
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: 20px;
    right: 20px;
    color: #fff;
    border-radius: 100%;
    z-index: 20;
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

.infoBox-close:hover {
    background: #fff;
    color: #000;
}

.listing-title h4 {
    float: left;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: #566985;
    padding-bottom: 20px;
}

.listing-title h4 a {
    color: #566985;
}

.listing-img-content {
    overflow: hidden;
}

.listing-img-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #292929;
    opacity: 0.3;
}

.cluster img {
    display: none
}

.cluster {
    width: 40px !important;
    height: 40px !important;
}

.cluster div {
    text-align: center;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: 600;
    border-radius: 100%;
    width: 40px !important;
    height: 40px !important;
    line-height: 38px !important;
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid #fff;
    -webkit-transition: all 300ms linear;
    transition: all 100ms 3 inear;
    animation: cluster-animation 1.5s infinite;
}

.cluster div:hover {
    background: #5d6275;
}

@keyframes cluster-animation {
    0%, 100% {
        box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
    }
    50% {
        box-shadow: 0px 0px 0px 9px rgba(0, 0, 0, 0.1);
    }
}

.map-popup-location-phone, .map-popup-location-info {
    float: left;
    color: #666;
    font-size: 13px;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
}

.map-popup-location-phone i, .map-popup-location-info i {
    padding-right: 10px;
    font-size: 14px;
}

.map-card-rainting {
    position: absolute;
    z-index: 10;
    left: 20px;
    top: -28px;
}

.map-card-rainting i {
    float: left;
    color: #FACC39;
    margin-right: 6px;
}

.map-popup-reviews-count {
    color: rgba(255, 255, 255, 0.9);
    position: relative;
    top: -4px;
}

.map-popup-category {
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: 500;
    color: #fff;
    z-index: 20;
    padding: 10px 12px;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
}

.pagination {
    margin-top: 20px;
    text-align: center;
    float: left;
    width: 100%;
}

.pagination a {
    display: inline-block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 6px;
    border: 1px solid #eee;
    box-sizing: border-box;
    position: relative;
    font-size: 13px;
    color: #888DA0;
    background: #fff;
    letter-spacing: 1px;
}

.pagination a.current-page, .pagination a:hover {
    color: #fff;
}

.pagination a i {
    font-size: 12px;
}

section.list-single-section {
    padding-bottom: 0;
    padding-top: 200px;
}

section.list-single-section .bg {
    height: 110%;
    top: -5%;
}

.list-single-header {
    z-index: 10;
    margin-top: 100px;
}

.list-single-header.list-single-header-inside {
    padding-bottom: 0;
    padding: 30px 0;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
}

.list-single-header-item h2 {
    float: left;
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 46px;
    font-weight: 700;
    padding-bottom: 15px;
}

.list-single-header-inside .list-single-header-item h2 {
    color: #334e6f;
    font-size: 36px;
}

.list-single-header-item h2 span, .list-single-header-item h2 a {
    font-size: 13px;
    font-weight: 400;
}

.list-single-header-item h2 a {
    font-weight: 600;
    color: #5ECFB1;
}

.list-single-header-item p {
    color: rgba(255, 255, 255, 0.71);
    font-weight: 500;
    text-align: left;
}

.list-single-header .section-separator {
    margin-top: 0;
}

.list-single-header .section-separator:before {
    left: 26px;
}

.list-single-header-item .listing-rating {
    float: left;
    margin-top: 10px;
}

.list-single-header-item .listing-rating i {
    float: left;
    margin-right: 6px;
}

.list-single-header-item .listing-rating span {
    color: rgba(255, 255, 255, 0.71);
    float: left;
    margin-left: 12px;
    position: relative;
    top: -2px;
}

.list-single-header.list-single-header-inside .list-single-header-item .listing-rating span {
    color: #666
}

.list-single-header-contacts {
    margin-top: 20px;
}

.list-single-header-contacts li {
    float: left;
    margin-right: 18px;
}

.list-single-header-contacts li a {
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.list-single-header-inside .list-single-header-contacts li a {
    color: #334e6f;
}

.list-single-header-contacts li i {
    padding-right: 10px;
}

.list-single-header-contacts li a:hover {
    color: rgba(255, 255, 255, 0.71)
}

.list-single-header-cat {
    margin-bottom: 20px;
}

.list-single-header-cat a {
    color: #fff;
    border-radius: 4px;
    padding: 10px 25px;
    float: left;
}

.list-single-header-cat span {
    float: left;
    color: #fff;
    padding-left: 30px;
    position: relative;
    top: 10px;
}

.list-single-header-cat span:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    height: 1px;
    width: 10px;
    background: rgba(255, 255, 255, 0.51);
}

.list-single-header-cat span i {
    padding-left: 4px;
}

.list-single-header-column {
    position: relative;
    margin-top: 20px;
}

.list-single-header-inside .list-single-header-column {
    margin-top: 0;
}

.list-single-header-column .viewed-counter, .list-single-header-column .custom-scroll-link {
    float: right;
    color: #fff;
    padding: 7px 12px;
    border-radius: 20px;
    margin-right: 10px;
    position: relative;
    top: 8px;
    border: 1px solid rgba(255, 255, 255, 0.41);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.11);
}

.list-single-header-inside .list-single-header-column .viewed-counter, .list-single-header-inside .list-single-header-column .custom-scroll-link {
    float: right;
    border: 1px solid #eee;
    border-radius: 4px;
    color: #999;
    margin-bottom: 12px;
}

.list-single-header-column .viewed-counter:hover, .list-single-header-column .custom-scroll-link:hover {
    background: rgba(255, 255, 255, 0.21);
}

.viewed-counter i, .list-single-header-column .custom-scroll-link i {
    padding-right: 10px;
}

.list-post-counter.single-list-post-counter {
    position: relative;
    float: left;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    padding: 6px 10px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.21);
    margin-left: 0px;
    top: 2px;
}

.list-post-counter.single-list-post-counter i {
    padding-left: 10px;
    color: #EC8500;
}

.list-single-header-inside .list-post-counter.single-list-post-counter {
    border: 1px solid #eee;
    color: #666;
}

.list-single-main-item {
    padding: 30px 30px;
    border-radius: 100%;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #eee;
    margin-bottom: 20px;
}

article .list-single-main-item {
    margin-bottom: 0;
}

.list-single-main-item-title h3 {
    color: #1A1F3E;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}

.list-single-main-item-title h3 a {
    color: #334e6f;
}

.list-single-main-item-title {
    margin: 0 0 20px 0;
}

.list-single-main-item p {
    text-align: left;
    color: #878C9F;
}

.listing-features li {
    float: left;
    width: 33.3%;
    text-align: left;
    margin-bottom: 15px;
    font-size: 13px;
    color: #888DA0;
    font-weight: 600;
}

.listing-features li i {
    padding-right: 10px;
}

.list-single-main-media {

    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.list-single-main-media img {
    width: 100%;
    height: auto;
}

.list-single-main-wrapper .breadcrumbs {
    border-radius: 6px;
    top: -25px;
    background: #fff;
    padding: 15px 20px;
}

.list-single-main-wrapper .breadcrumbs a, .list-single-main-wrapper .breadcrumbs span {
    float: left;
    color: #fff;
    padding-right: 20px;
    font-weight: 600;
}

.list-single-main-wrapper .breadcrumbs span {
    color: rgba(255, 255, 255, 0.71);
}

.list-single-main-wrapper .breadcrumbs a:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 100%;
    right: 8px;
    top: 50%;
    margin-top: -2px;
}

.list-single-facts {
    margin: 10px 0 20px;
    padding: 20px 30px;
    border-radius: 6px;
}

.list-single-facts .inline-facts-wrap {
    width: 33.3%;
    float: left;
    color: #fff;
    position: relative;
}

.list-single-facts .inline-facts-wrap:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 70px;
    margin-top: -35px;
    background: rgba(255, 255, 255, 0.21);
}

.list-single-facts .inline-facts-wrap:last-child:before {
    display: none;
}

.list-single-facts .inline-facts-wrap i {
    font-size: 30px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.11);
    border: 1px solid rgba(255, 255, 255, 0.21);
}

.list-single-facts .inline-facts-wrap .num, .single-facts .inline-facts-wrap .num {
    display: block;
    margin: 12px 0;
    font-size: 30px;
    font-weight: 800;
}

.single-facts .inline-facts-wrap {
    float: left;
    width: 25%;
    padding: 0 25px;
}

.single-facts .inline-facts-wrap .num {
    color: #fff;
    font-size: 64px;
}

.list-single-facts .inline-facts-wrap h6, .single-facts .inline-facts-wrap h6 {
    color: rgba(255, 255, 255, 0.71);
    font-size: 13px;
    font-weight: 500;
}

.single-facts .inline-facts-wrap h6 {
    font-size: 15px;
    font-weight: 500;
    position: relative;
    color: rgba(255, 255, 255, 0.91);
}

.single-facts .inline-facts-wrap h6:before {
    content: '';
    position: absolute;
    top: -12px;
    left: 50%;
    height: 1px;
    background: #fff;
    margin-left: -25px;
    opacity: 0.4;
    width: 50px;
}

.tags-stylwrap a {
    float: left;
    padding: 10px 20px;
    border-radius: 4px;
    color: #888DA0;
    font-weight: 500;
    margin-right: 6px;
    border: 1px solid #eee;
}

.list-single-tags.blog-tags {
    margin-bottom: 15px;
    float: left;
}

.list-single-contacts {
    margin-top: 20px
}

.list-single-contacts li {
    float: left;
    width: 100%;
}

.list-single-contacts li span, .list-single-contacts li a {
    float: left;
    text-align: left;
    font-weight: 500;
}

.list-single-contacts li span i {
    margin-right: 7px;
    font-size: 14px;
}

.list-single-contacts li a {
    margin-left: 20px;
    color: #878C9F;
}

.promo-link {
    position: absolute;
    bottom: 40px;
    left: 50px;
    padding: 16px 55px 16px 70px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 700;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
    z-index: 10;
}

.promo-link i {
    position: absolute;
    left: 10px;
    top: 50%;
    height: 34px;
    width: 34px;
    line-height: 34px;
    color: #878C9F;
    border-radius: 100%;
    margin-top: -17px;
    background: #fff;
}

.promo-link:hover i {
    background: #2C3B5A;
    color: #fff;
}

.list-single-gallery {
    float: left;
    width: 100%;
}

.list-single-gallery .box-item a.gal-link {
    top: -50%;
    left: 50%;
    margin: -20px 0 0 -20px;
}

.list-single-gallery .box-item {
    border-radius: 6px;
}

.list-single-gallery .box-item:hover a.gal-link {
    top: 50%;
}

.box-item:hover:before {
    opacity: 1;
}

.box-widget-item {
    margin-bottom: 30px;
}

.box-widget-item-header {
    display: block;
    margin-bottom: 22px;
}

.box-widget-item-header h3 {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: #334e6f;
}

.list-author-widget-header {
    padding: 70px 20px;
}

.list-author-widget-header img {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    left: 50%;
    margin-left: -50px;
    bottom: -30px;
    border: 6px solid rgba(255, 255, 255, 0.2);
    border-radius: 100%;
}

.list-author-widget-link {
    display: inline-block;
    position: relative;
    top: -20px;
}

.list-author-widget-link a {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
}

.list-author-widget-link a:hover {
    color: rgba(255, 255, 255, 0.71);
}

.box-widget {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #eee;
    overflow: hidden;
    float: left;
    width: 100%;
}

.no-bor-rad {
    border-radius: 0;
}

.box-widget-content {
    float: left;
    width: 100%;
    position: relative;
    padding: 25px 30px 30px;
}

.banner-wdget {
    overflow: hidden;
    border-radius: 6px;
}

.banner-wdget .overlay {
    opacity: 0.4;
}

.banner-wdget-content {
    padding: 90px 30px 70px;
    z-index: 10;
}

.banner-wdget-content h4 {
    font-weight: 600;
    color: #fff;
    font-size: 16px;
}

.banner-wdget-content a {
    font-weight: 600;
    color: #fff;
    padding: 12px 30px;
    display: inline-table;
    margin-top: 25px;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.banner-wdget-content a:hover {
    background: #24324F;
}

.pricerange span {
    float: left;
    margin-right: 20px;
    color: #878C9F;
    font-weight: 700;
}

.pricerange {
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: #5ECFB1;
}

.claim-widget-link {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #eee;
}

.claim-widget-link span, .claim-widget-link a {
    float: left;
    text-align: left;
    font-weight: 500;
}

.claim-widget-link a:hover {
    color: #24324F;
}

.claim-widget-link span {
    color: #878C9F;
    margin-right: 20px;
}

.search-widget input {
    float: left;
    width: 100%;
    border: none;
    background: #fff;
    height: 50px;
    padding: 0 80px 0 20px;
    z-index: 1;
}

.search-widget .search-submit {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 50px;
    line-height: 50px;
    z-index: 2;
    color: #fff;
    border: none;
    cursor: pointer;
}

.box-widget-item .list-single-tags a {
    margin-bottom: 10px;
    border: none;
    color: #fff;
    font-size: 11px;
}

.cat-item li {
    float: left;
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 15px;
    text-align: left;
    position: relative;
}

.cat-item li:last-child {
    margin-bottom: 0;
}

.cat-item li:before {
    content: '';
    position: absolute;
    bottom: 6px;
    width: 100px;
    height: 1px;
    background: #eee;
    left: 50%;
    margin-left: -25px;
}

.cat-item li a {
    float: left;
    font-size: 12px;
    color: #666;
    font-weight: 800;
}

.cat-item li span {
    float: right;
    color: #999;
}

.list-author-widget-contacts {
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    float: left;
    width: 100%;
}

.list-author-widget-contacts p {
    text-align: left;
}

.list-author-widget-contacts.list-item-widget-contacts {
    margin-top: 0;
    border-bottom: 0;
    padding-bottom: 0;
}

.list-author-widget-contacts li {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.list-author-widget-contacts.list-item-widget-contacts li {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.list-author-widget-contacts.list-item-widget-contacts li:last-child {
    margin-bottom: 0;
}

.list-author-widget-contacts li span, .list-author-widget-contacts li a {
    float: left;
    text-align: left;
    font-weight: 500;
}

.list-author-widget-contacts li span {
    min-width: 80px;
}

.list-author-widget-contacts li span i {
    margin-right: 7px;
    font-size: 14px;
}

.list-author-widget-contacts li a {
    margin-left: 20px;
    color: #1A1F3E;
}

.list-widget-social {
    margin-top: 20px;
}

.btn-contact {
    margin-top: 40px;
    float: left;
}

.list-widget-social, .list-widget-social li {
    float: left;
}

.list-widget-social li {
    margin-right: 8px;
}

.list-widget-social li a {
    width: 36px;
    height: 36px;
    display: block;
    line-height: 36px;
    border-radius: 4px;
    color: #fff;
}

.list-widget-social li a:hover {
    background: #2C3B5A;
}

.opening-hours ul li {
    width: 100%;
    margin-bottom: 15px;
    float: left;
    padding-bottom: 15px;
    color: #878C9F;
    border-bottom: 1px solid #eee;
}

.opening-hours ul li span.opening-hours-time {
    float: right;
    font-weight: 500;
    color: #999;
}

.opening-hours ul li span.opening-hours-day {
    float: left;
    color: #878C9F;
    font-weight: 600;
}

.opening-hours .btn {
    float: left;
    margin-top: 20px;
}

.current-status {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 700;
    color: #5ECFB1;
    font-size: 15px;
}

.current-status i {
    color: #ccc;
    font-size: 16px;
    padding-right: 30px;
    position: relative;
    top: 3px;
}

.reviews-comments-wrap {
    display: inline-table;
    margin-top: 30px;
}

.reviews-comments-item-text .listing-rating {
    float: right;
}

.reviews-comments-item-text .listing-rating i {
    float: left;
    margin-right: 6px;
    font-size: 12px;
}

.reviews-comments-item {
    padding: 0 0 30px 110px;
    position: relative;
    float: left;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 1px solid #eee;
}

.reviews-comments-item.reply-comment-item {
    width: 90%;
    margin-left: 10%;

    padding: 30px 20px 30px 110px;

    border-radius: 6px;
}

.reviews-comments-item.reply-comment-item .review-comments-avatar {
    top: 30px;
}

.reviews-comments-item.reply-comment-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: -10%;
    margin-left: 30px;
    width: 40px;
    height: 40px;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.reviews-comments-item:last-child {
    border: none;
    margin-bottom: 0;
}

.review-comments-avatar {
    position: absolute;
    top: 0;
    left: 20px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 100%;
}

.review-comments-avatar img {
    width: 80px;
    height: 80px;
    float: left;
}

.reviews-comments-item-text {
    float: left;
    width: 100%;
    position: relative;
    padding: 0 20px;
}

.reviews-comments-item-text .new-dashboard-item {
    top: 0;
}

.reviews-comments-item-text h4 {
    text-align: left;
    padding-bottom: 25px;
    float: left;

    font-size: 16px;
    font-weight: 500;
}

.reviews-comments-item-text p {
    text-align: left;
}

.reviews-comments-item-date {
    float: left;
    margin-top: 18px;
    font-weight: 500;
    color: #999;
}

.reviews-comments-item-date i {
    margin-right: 10px;
}

.leave-rating, .leave-rating-title {
    float: left;
}

.leave-rating-wrap {
    float: left;
    margin: 30px 0 30px 0;
    width: 100%;
}

.leave-rating-title {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #666;
    position: relative;
    top: -3px;
}

.leave-rating input[type="radio"] {
    display: none;
}

.leave-rating label {
    font-size: 14px;
    float: right;
    letter-spacing: 4px;
    color: #FACC39;
    cursor: pointer;
    transition: 0.3s;
}

.leave-rating label:hover:before, .leave-rating label.selected:before {
    content: "\f005";
}

.leave-rating:hover input[type="radio"]:checked ~ label:before, .leave-rating input[type="radio"]:checked ~ label:before, .leave-rating label:hover ~ label:before {
    content: "\f005" !important;
}

.box-widget.widget-posts li {
    padding: 8px 0;
    margin-bottom: 19px;
}

.box-widget.widget-posts.blog-widgets li:last-child {
    margin-bottom: 0;
}

.box-widget.widget-posts .widget-posts-img {
    float: left;
    width: 25%;
}

.box-widget.widget-posts .widget-posts-img img {
    border-radius: 4px;
    width: 100%;
    height: auto;
}

.box-widget.widget-posts .widget-posts-descr {
    float: left;
    width: 60%;
    margin-left: 6%;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.box-widget.widget-posts .widget-posts-descr a {
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 20px;
    color: #878C9F;
}

.box-widget.widget-posts .widget-posts-date {
    float: left;
    width: 100%;
    margin-top: 17px;
    font-weight: 500;
    color: #999;
    font-size: 12px;
}

.box-widget.widget-posts .widget-posts-date i {
    padding-right: 10px;
}

.widget-posts-link {
    float: left;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    top: 10px;
    color: #878C9F;
}

.widget-posts-link:hover {
    color: #000;
}

.widget-posts-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 26px;
    height: 1px;
    border-bottom: 1px solid #eee;
}

.widget-posts-link span {
    float: right;
    width: 24px;
    height: 24px;
    line-height: 24px;
    position: relative;
    border-radius: 100%;
    margin-left: 10px;
    color: #fff;
    font-size: 10px;
}

/*-------------scroll-nav-wrapper---------------------------------------*/
.scroll-nav-wrapper {
    background: #fff;
    border-bottom: 1px solid #eee;
}

.scroll-nav-wrapper .scroll-nav li {
    float: left;
    position: relative;
}

.scroll-nav-wrapper .scroll-nav.inline-scroll-container li {
    display: inline-block;
    float: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.scroll-nav-wrapper .scroll-nav li:before, .scroll-nav-wrapper .scroll-nav li:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: 20px;
    margin-top: -10px;
    background: #eee;
    width: 1px;
}

.scroll-nav-wrapper .scroll-nav li:after {
    display: none;
}

.scroll-nav-wrapper .scroll-nav li:first-child:after {
    right: inherit;
    left: 0;
    display: block;
}

.scroll-nav-wrapper .scroll-nav li a {
    padding: 23px 30px;
    float: left;
    position: relative;
    font-weight: 700;
    color: #888DA0;
    font-size: 13px;
}

.scroll-nav-wrapper .scroll-nav li a:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 1px;
}

.scroll-nav-wrapper .scroll-nav.inline-scroll-container li a:before {
    bottom: -4px;
}

.scroll-nav-wrapper .save-btn {
    float: right;
    position: relative;
    color: #878C9F;
    border-radius: 6px;
    top: 20px;
    padding: 5px 15px;
    font-size: 13px;
    font-weight: 500;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
}

.scroll-nav-wrapper .save-btn i {
    padding-right: 10px;
}

.scroll-nav-wrapper .scroll-nav li a.act-scrlink:before {
    width: 100%;
}

/*-------------Forms---------------------------------------*/
.custom-form {
    float: left;
    width: 100%;
    position: relative;
}

.custom-form .nice-select {
    color: #fff;
    border-radius: 4px;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.custom-form .nice-select .list {
    width: 100%;
    padding: 80px 12px 10px;
    background: #fff;

    border-radius: 4px;
}

.custom-form .nice-select .list li {
    float: left;
    width: 100%;
    padding: 5px 10px 6px;
    color: #666;
    font-size: 13px;
    border-radius: 2px;
    min-height: 20px;
    line-height: 20px;
}

.custom-form .nice-select .list li:hover, .custom-form .nice-select .list li.selected {
    color: #fff;
}

.custom-form textarea, .custom-form input[type="text"], .custom-form input[type=email], .custom-form input[type=password], .custom-form input[type=button] {
    float: left;
    border: 1px solid #eee;
    background: #f9f9f9;
    width: 100%;
    padding: 15px 20px 15px 55px;
    border-radius: 6px;
    color: #666;
    font-size: 13px;
    -webkit-appearance: none;
}

.custom-form .nice-select input {
    padding-left: 20px;
}

.custom-form input::-webkit-input-placeholder, .custom-form textarea::-webkit-input-placeholder {
    color: #666;
    font-weight: 500;
    font-size: 13px;
    font-family: 'AvantGarde', sans-serif;
}

.custom-form input:-moz-placeholder, .custom-form textarea:-moz-placeholder {
    color: #888DA0;
    font-weight: 600;
    font-size: 13px
}

.custom-form textarea {
    height: 200px;
    resize: none;
    padding: 25px 20px;
    -webkit-appearance: none;
}

.custom-form input {
    margin-bottom: 20px;
}

.filter-tags input, .custom-form .filter-tags input {
    float: left;
    position: relative;
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 0;
    width: 20px;
    height: 20px;
    position: relative;
    color: #fff;
    background: #fff !important;
    -webkit-appearance: none;
}

.filter-tags input:checked:after, .custom-form .filter-tags input:checked:after {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f00c";
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 20;
}

.filter-tags label, .custom-form .filter-tags label {
    float: left;
    padding: 0 10px;
    position: relative;
    top: 4px;
    color: #888DA0;
    font-weight: 600;
    width: auto;
}

.custom-form label {
    float: left;
    position: relative;
    width: 100%;
    text-align: left;
    font-weight: 500;
    color: #666;
}

.main-register .custom-form label {
    padding-bottom: 12px;
}

.custom-form label i {
    padding-right: 12px;
    font-size: 14px;
    position: absolute;
    top: 16px;
    left: 20px;
}

.custom-form button {
    float: left;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 30px;
    background: none;
    -webkit-appearance: none;
}

.custom-form .quantity span {
    float: left;
    position: relative;
    top: 16px;
    padding-left: 19px;
}

.custom-form .quantity span i {
    padding-right: 10px;
    font-size: 14px;
}

.custom-form .quantity input.minus, .custom-form .quantity input.plus, .custom-form .quantity input.qty {
    width: 50px;
    height: 50px;
    border-radius: 0;
    text-align: center;
    padding: 0;
    cursor: pointer;
    -webkit-appearance: none;
}

.custom-form .quantity input.plus {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.custom-form .quantity input.minus {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.quantity-item {
    float: right;
}

.custom-form .quantity input.qty {
    color: #fff;
    width: 100px;
    border-right: none;
    border-left: none;
    cursor: default;
    -webkit-appearance: none;
}

.onoffswitch {
    position: relative;
    width: 83px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 21px;
    background-color: #4DB7FE;
    color: #FFFFFF;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 21px;
    background-color: #4DB7FE;
    color: #FFFFFF;
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 21px;
    margin: 4.5px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 49px;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

.main-register .custom-form input[type="text"], .main-register .custom-form input[type=email],
.main-register .custom-form input[type=password] {
    padding-left: 20px;
}

/*-------------Counter---------------------------------------*/
.counter-widget {
    padding: 25px 30px;
    position: relative;
}

.countdown-item {
    float: left;
    width: 33.3%;
    color: #fff;
    position: relative;
    z-index: 2;
}

.countdown-item:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 50px;
    margin-top: -25px;
    background: rgba(255, 255, 255, 0.21);
}

.countdown-item.no-dec:before {
    display: none;
}

.countdown-item span {
    font-size: 34px;
    font-weight: 700;
}

.countdown-item p {
    padding-bottom: 0;
    color: #fff;
}

.countdown-item-seconds {
    position: absolute;
    bottom: -60px;
    right: -40px;
    font-size: 90px;
    color: #fff;
    z-index: 1;
    opacity: 0.4;
    font-weight: 700;
}

/*-------------Team---------------------------------------*/

.team-box {
    float: left;
    width: 33.3%;
    position: relative;
    padding: 10px 4px;
}

.section-team .team-box {
    padding: 10px 8px;
}

.team-photo {
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 6px;
    max-width: 60%;
    height: auto;
}

.section-team .team-photo {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.team-info {
    float: left;
    width: 100%;
    text-align: left;
}

.section-team .team-info {
    border: 1px solid #eee;
    padding: 25px 30px;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.team-info h3, .team-info h4 {
    float: left;
    width: 100%;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
}

.team-info h4 {
    font-size: 11px;
    color: #666;
}

.team-info p {
    color: #666;
}

.team-social li {
    float: left;
    margin-right: 10px;
}

.team-social li a:hover {
    color: #999;
}

/*-------------Map---------------------------------------*/
.map-container {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.fw-map {
    height: 600px;
}

.map-container.column-map {
    width: 50%;
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
    overflow: hidden;
}

.map-container.column-map.right-pos-map {
    right: 0;
}

.map-container.column-map.left-pos-map {
    left: 0;
}

.map-container #map-main {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    overflow: hidden;
}

#singleMap {
    width: 100%;
    position: relative;
    height: 300px;
    float: left;
    margin-bottom: 30px;
}

.box-widget #singleMap {
    margin-bottom: 10px;
}

.mapzoom-in, .mapzoom-out {
    position: fixed;
    z-index: 100;
    top: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: #fff;
    line-height: 40px;
    margin-top: -20px;
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.4);
    -webkit-transform: translate3d(0, 0, 0);
}

#singleMap .mapzoom-in, #singleMap .mapzoom-out, .home-map .mapzoom-in, .home-map .mapzoom-out, .fw-map .mapzoom-in, .fw-map .mapzoom-out {
    position: absolute;
    right: 20px;
}

.map-container.column-map.right-pos-map .mapzoom-in, .map-container.column-map.right-pos-map .mapzoom-out {
    right: 30px;
}

.map-container.column-map.left-pos-map .mapzoom-in, .map-container.column-map.left-pos-map .mapzoom-out {
    left: 30px;
}

.mapzoom-in:before, .mapzoom-out:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f068";
}

.mapzoom-in:before {
    content: "\f067";
}

.mapzoom-in {
    margin-top: -80px;
}

.mapnavigation {
    position: absolute;
    bottom: 50px;
    right: 7px;
    z-index: 30;
    width: 170px;
}

.mapnavigation a {
    width: 70px;
    padding: 8px 0;
    border-radius: 4px;
    color: #fff;
    float: left;
    margin-left: 10px;
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
}

.mapnavigation a, .mapzoom-in:hover, .mapzoom-out:hover {
    background: #2F3B59;
}

/*-------------accordion---------------------------------------*/
.accordion {
    float: left;
    width: 100%;
    position: relative;
}

.accordion a.toggle {
    border: 1px solid #eee;
    padding: 18px 30px;
    float: left;
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    transition: all 300ms linear;
    margin-bottom: 15px;
}

.accordion a.toggle i {
    position: absolute;
    right: 24px;
    top: 17px;
    font-size: 16px;

}

.accordion a.toggle.act-accordion {
    color: #fff;
    border-color: transparent;
}

.accordion a.toggle.act-accordion i {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.accordion-inner {
    display: none;
    float: left;
    width: 100%;
    position: relative;
    padding: 15px 30px;
    background: #fff;
    margin: 0 0 15px;
    text-align: left;
}

.accordion-inner.visible {
    display: block;
}

/*------dashboard ------------------------------------------------*/
.act-widget h4 {
    float: left;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    top: 12px;
}

.act-widget {
    margin-bottom: 20px;
}

.act-widget-header {
    float: left;
    width: 100%;
    position: relative;
    border-radius: 6px;
    background: #f8f8f8;
    padding: 10px 20px;
    border: 1px solid #eee;
    margin-bottom: 15px;
}

.act-widget-header .onoffswitch {
    float: right;
    top: 6px;
}

.profile-edit-page-header {
    float: left;
    width: 100%;
    position: relative;
    padding: 15px 30px;
    border-radius: 6px;
    border: 1px solid #eee;
    margin-bottom: 30px;
    background: #F8FBFE;
}

.profile-edit-header {
    float: left;
    width: 100%;
    position: relative;
    padding-bottom: 30px;
}

.profile-edit-page-header h2 {
    text-align: left;
    font-size: 26px;
    color: #334e6f;
    font-weight: 500;
    float: left;
}

.profile-edit-page-header .breadcrumbs {
    float: right;
    position: relative;
    top: 8px;
}

.profile-edit-page-header .breadcrumbs a, .profile-edit-page-header .breadcrumbs span {
    float: left;
    margin-left: 24px;
    position: relative;
    font-weight: 500;
}

.profile-edit-page-header .breadcrumbs a:before {
    content: '';
    position: absolute;
    top: 50%;
    right: -16px;
    margin-top: -3px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
}

.user-profile-avatar {
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: inline-block;
    margin: 25px 0 15px;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2)
}

.user-profile-avatar img {
    width: 150px;
    height: 150px;
}

.user-profile-rating i {
    margin: 0 4px;
}

.user-profile-rating .card-popup-rainingvis span {
    color: #fff;
    font-weight: 500;
}

.change-photo-btn {
    float: left;
    margin-top: 20px;
}

.photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
}

.photoUpload {
    float: left;
    position: relative;
    padding: 15px 30px;
    color: #fff;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
}

.photoUpload i {
    margin-right: 6px;
}

.user-profile-menu-wrap {
    padding: 15px 20px;
    background: #F8FBFE;
    border: 1px solid #eee;
    border-radius: 4px;
}

.user-profile-menu-wrap:before, .user-profile-menu-wrap:after {
    position: absolute;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f013";
    color: #999;
}

.user-profile-menu-wrap:before {
    top: 20px;
    right: 20px;
    font-size: 33px;
    opacity: 0.3;
    -webkit-animation: spinnerRotate 9s infinite linear;
    -moz-animation: spinnerRotate 9s infinite linear;
    -o-animation: spinnerRotate 9s infinite linear;
    animation: spinnerRotate 9s infinite linear;
}

.user-profile-menu-wrap:after {
    top: 40px;
    right: 43px;
    font-size: 23px;
    opacity: 0.2;
    -webkit-animation: spinnerRotate2 5s infinite linear;
    -moz-animation: spinnerRotate2 5s infinite linear;
    -o-animation: spinnerRotate2 5s infinite linear;
    animation: spinnerRotate2 5s infinite linear;
}

@-webkit-keyframes spinnerRotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spinnerRotate {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spinnerRotate {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinnerRotate2 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
    }
}

@-moz-keyframes spinnerRotate2 {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(-360deg);
    }
}

@-ms-keyframes spinnerRotate2 {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(-360deg);
    }
}

.user-profile-menu {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    float: left;
    width: 100%;
}

.user-profile-menu h3 {
    text-align: left;
    font-size: 16px;
    padding-bottom: 20px;
    font-weight: 600;
    color: #98AAB8;
}

.user-profile-menu li {
    width: 100%;
    margin-bottom: 14px;
    text-align: left;
    float: left;
}

.user-profile-menu li a {
    font-weight: 600;
    color: #50596E;
    position: relative;
    min-width: 90px;
    float: left;
    padding: 5px;
}

.user-profile-menu li a i {
    padding-right: 25px;
    width: 20px;
    float: left;
    text-align: center;
    opacity: 0.4;
    position: relative;
    top: 2px;
}

.user-profile-menu li a span {
    position: absolute;
    top: 50%;
    right: -30px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    line-height: 20px;
    background: #98AAB8;
    color: #fff;
    border-radius: 100%;
    text-align: center;
    font-weight: 300px;
    font-size: 10px;
}

.user-profile-menu li a:hover i, .user-profile-menu li a.user-profile-act i {
    opacity: 1;
}

.log-out-btn {
    float: left;
    padding: 12px 35px;
    border: 2px solid;
    background: #DAF4FF;
    font-weight: 600;
    font-size: 12px;
    border-radius: 6px;
}

.log-out-btn:hover {
    color: #fff;
}

.profile-edit-header h4 {
    text-align: left;
    font-size: 18px;
    color: #98AAB8;
    font-weight: 700;
    padding-bottom: 20px;
}

.profile-edit-container .custom-form label {
    float: left;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 500;
}

.profile-edit-container .custom-form label i {
    top: 42px;
}

.custom-form.no-icons input, .custom-form.no-icons textarea {
    padding-left: 10px;
}

.pass-input-wrap span {
    position: absolute;
    right: 20px;
    cursor: pointer;
    bottom: 36px;
    z-index: 10;
}

.profile-edit-container.add-list-container {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    float: left;
    width: 100%;
}

.profile-edit-container.add-list-container:first-child {
    margin-top: 0;
}

.add-list-media-header {
    float: left;
    width: 100%;
    padding: 15px 20px 3px;
    background: #F8FBFE;
    border: 1px solid #eee;
}

.add-list-media-header span {
    text-align: left;
    float: left;
}

.fuzone {
    position: relative;
    border: 2px dashed #eee;
    border-radius: 3px;
    background: #f9f9f9;
    transition: all 0.3s linear;
    margin-bottom: 10px;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    min-height: 150px;
    margin-top: 20px;
    float: left;
    cursor: pointer;
}

.fuzone input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 100;
    cursor: pointer;
}

.fuzone .fu-text {
    text-align: center;
    margin: 50px 0;
    font-size: 12px;
    color: #98AAB8;
    position: relative;
}

.fuzone .fu-text i {
    font-size: 54px;
    width: 100%;
    padding-bottom: 10px;
    transition: all 0.3s linear;
}

.add-list-media-wrap label {
    margin-top: 18px;
}

.booking-details {
    margin-bottom: 15px;
    font-size: 13px;
}

.booking-details span.booking-title {
    font-weight: 600;
    color: #999;
}

.booking-details span.booking-text {
    color: #50596E;
    font-weight: 500;
    position: relative;
}

.booking-details a:hover {
    color: #999;
}

.radio {
    color: #999;
    font-size: 13px;
    position: relative;
    top: -2px;
}

.radio span {
    position: relative;
    padding-left: 30px;
}

.radio span:after {
    content: '';
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    position: absolute;
    left: 0;
    top: -1px;
    border-radius: 3px;
    box-sizing: border-box;
}

.radio input[type="radio"] {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}

.radio input[type="radio"]:checked + span {
    color: #98AAB8;
}

.radio input[type="radio"]:checked + span:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f00c";
    font-size: 13px;
    position: absolute;
    top: 3px;
    left: 4px;
    z-index: 20;
}

.dashboard-list-box {
    border: 1px solid #eee;
    border-radius: 10px;
}

.dashboard-header {
    padding: 20px 30px;
    border-bottom: 1px solid #eee;
    background: #F8FBFE;
}

.dashboard-header h3 {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.dashboard-list {
    float: left;
    padding: 25px 30px;
    position: relative;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.dashboard-list:nth-child(2n+1) {
    background: #fbfbfb;
}

.dashboard-list:last-child {
    border: none;
}

.dashboard-message-avatar {
    float: left;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 100%;
}

.dashboard-message-text .btn {
    margin-top: 10px;
    float: left;
    background: #5ECFB1 !important;
    padding: 12px 20px;
    font-weight: 500;
    font-size: 12px;
}

.dashboard-message-text .btn:hover {
    color: rgba(255, 255, 255, 0.71)
}

.dashboard-message-avatar img {
    width: 50px;
    height: 50px;
}

.dashboard-message-text {
    margin-left: 30px;
    text-align: left;
    padding-left: 50px;
}

.dashboard-message-text h4, .dashboard-listing-table-text h4 {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 20px;
    color: #50596E;
}

.dashboard-message-text h4 span {
    font-weight: 500;
    font-size: 12px;
}

.reply-mail {
    text-align: left;
    font-weight: 500;
}

.reply-mail a {
    font-weight: 400;
}

.reply-mail a:hover {
    color: #999;
}

.new-dashboard-item {
    position: absolute;
    right: 30px;
    top: 22px;
    padding: 6px 15px;
    border-radius: 4px;
    color: #fff;
    background: #5ECFB1;
    z-index: 10;
}

.dashboard-listing-table-image {
    float: left;
    width: 30%;
    padding: 0 30px 0 0;
}

.dashboard-listing-table-image img {
    width: 100%;
    height: auto;
    border-radius: 6px;
}

.dashboard-listing-table-text {
    float: left;
    text-align: left;
    width: 70%;
}

.dashboard-listing-table-text h4 a {
    color: #50596E;
}

.dashboard-listing-table-address {
    float: left;
    padding-bottom: 20px;
}

.dashboard-listing-table-address a {
    font-weight: 500;
    color: #666;
}

.dashboard-listing-table-address i {
    padding-right: 10px;
}

.dashboard-listing-table-opt li {
    float: left;
    margin-right: 10px;
}

.dashboard-listing-table-opt {
    margin-top: 15px;
}

.dashboard-listing-table-opt li a {
    color: #fff;
    padding: 9px 22px;
    border-radius: 30px;
    background: #5ECFB1;
    float: left;
}

.dashboard-listing-table-opt li a.del-btn:hover {
    background: #f91942;
}

.profile-edit-header h4 span {
    color: #5ECFB1;
}

.statistic-container {
    margin-bottom: 20px;
}

.statistic-item-wrap {
    float: left;
    width: 25%;
    padding: 0 10px;
    margin-bottom: 20px;
}

.statistic-item {
    padding: 20px 30px;
    border-radius: 6px;
}

.statistic-item i {
    font-size: 74px;
    position: absolute;
    bottom: 40px;
    right: 20px;
    color: rgba(255, 255, 255, 0.41);
}

.statistic-item h5, .statistic-item-numder {
    float: left;
    width: 100%;
    text-align: left;
    color: #fff;
}

.statistic-item-numder {
    font-weight: 800;
    font-size: 34px;
    padding-bottom: 10px;
}

.notification {
    padding: 18px 20px;
    border-radius: 4px;
    margin-bottom: 40px;
}

.notification p {
    color: #fff;
    text-align: left;
    padding-bottom: 0;
}

.notification p a {
    color: rgba(255, 255, 255, 0.71);
    font-weight: 600;
    padding: 0 5px;
}

.notification.success {
    background: #5ECFB1;
}

.notification.waitforreview {
    background: #FBC54F;
}

.notification.reject {
    background: #EA4D37;
}

.notification-close {
    position: absolute;
    top: 50%;
    right: 20px;
    height: 20px;
    margin-top: -10px;
    color: rgba(255, 255, 255, 0.71);
}

.notification-close:hover {
    color: #fff;
}

.dashboard-list-box.activities .dashboard-list {
    padding-left: 0;
}

.dashboard-list-box.activities .dashboard-message-text {
    padding-left: 0;
}

.dashboard-list-box.activities .dashboard-message-text i {
    padding-right: 20px;
}

.dashboard-list-box.activities .new-dashboard-item {
    cursor: pointer;
}

.dashboard-list-box.activities .new-dashboard-item:hover {
    background: #EA4D37;
}

/*-------------Button---------------------------------------*/
.btn {
    padding: 12px 30px;
    border-radius: 6px;
    color: #878C9F;
    font-weight: 600;
}

.btn-home {
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
}

.btn.color-bg:hover {
    background: #2F3B59;
}

.btn i {
    padding-left: 10px;
}

.btn.big-btn {
    padding: 15px 40px;
}

.btn.circle-btn {
    border-radius: 30px;
}

.btn-home.square-btn {
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    height:80px;
    width:80px;
    background: none;
    border: none;
}

.btn-home.square-btn:hover {
    border-radius: 6px;
    color: #EC8500;
    font-weight: 600;
    border-radius: 10px;
    height:80px;
    width:80px;
    background: none;
    border: none;
}

.btn-home.square-btn.selected {
    border-radius: 6px;
    color: #EC8500;
    font-weight: 600;
    border-radius: 10px;
    height:80px;
    width:80px;
    background: none;
    border: none;
}

.dark {
    background: #1A1F3E;
    width:100%;

}

.p-home {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

div.home:hover p.p-home, div.home:hover a.btn-home {
    color: #EC8500;
}

.p-home.selected {
    color: #EC8500;
    font-size: 13px;
    font-weight: 500;
}

.btn.transparent-btn {
    border: 2px solid;
}

.btn.float-btn {
    float: left;
    margin: 10px 0;
    color: #666;
}

.btn.btn-light {
    float: left;
    margin: 10px 0;
    color: #fff;
}

.btn.transparent-btn:hover, .btn.flat-btn {
    color: #fff;
}

.dec-btn:before {
    content: '';
    position: absolute;
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
    border: 1px solid #eee;
    border-radius: 30px;

}

.list-author-widget-text .btn {
    display: inline-table;

    margin-top: 16px;
}

.trs-btn {
    display: inline-block;
    padding: 15px 35px;
    color: #fff;
    margin-top: 30px;
    border-radius: 30px;
    position: relative;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
}

.list-single-btn {
    display: inline-block;
    padding: 15px 35px;
    background: #fff;
    margin-top: 90px;
    border-radius: 30px;
    position: relative;
    border: 1px solid #eee;
    font-size: 14px;
}

.list-single-btn i {
    padding-left: 10px;
}

/*------ Grid Gallery------------------------------------------------*/
.gallery-items.mr-bot {
    margin-bottom: 40px;
}

.grid-item-holder {
    float: left;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}

.spad .grid-item-holder {
    padding: 10px;
}

.grid-small-pad .grid-item-holder {
    padding: 10px 20px 10px 0;
}

.gallery-item, .grid-sizer {
    width: 33.3%;
    position: relative;
    float: left;
}

.gallery-item-second,
.grid-sizer-second {
    width: 66.6%;
}

.four-column .gallery-item {
    width: 25%;
}

.four-column .gallery-item.gallery-item-second {
    width: 50%;
}

.gallery-item img, .grid-sizer img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
}

/*------ Image collage------------------------------------------------*/
.images-collage-item img {
    width: 100%;
    height: 100%;
}

.images-collage-item {
    border-radius: 100%;
    overflow: hidden;
}

.images-collage-main {
    position: relative;
    z-index: 3;
    width: 160px;
    height: 160px;
    display: inline-block;
    margin-top: 90px;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.images-collage-other {
    width: 90px;
    height: 90px;
    position: absolute;
    z-index: 1;
}

.images-collage-title {
    position: absolute;
    right: 30%;
    width: 180px;
    padding: 12px 0;
    background: #fff;
    z-index: 10;
    top: 20px;
    border-radius: 30px;
    font-weight: 800;
    font-size: 18px;
    color: #566985;
    text-transform: uppercase;
    font-family: 'AvantGarde', sans-serif;
}

.images-collage:before {
    content: '';
    position: absolute;
    width: 250px;
    height: 250px;
    top: 50%;
    left: 50%;
    margin-top: -125px;
    margin-left: -125px;
    border: 2px dotted #fff;
    z-index: -1;
    opacity: 0.6;
    border-radius: 100%;
}

.anim-col {
    animation: anim-col 4.15s infinite;
    -o-animation: anim-col 4.15s infinite;
    -ms-animation: anim-col 4.15s infinite;
    -webkit-animation: anim-col 4.15s infinite;
    -moz-animation: anim-col 4.15s infinite;
}

@-moz-keyframes anim-col {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: -50px
    }
    100% {
        margin-top: 0
    }

}

@-webkit-keyframes anim-col {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: -50px
    }
    100% {
        margin-top: 0
    }

}

@keyframes anim-col {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: -50px
    }
    100% {
        margin-top: 0
    }

}

/*------ price tables------------------------------------------------*/
.price-item {
    float: left;
    width: 33.3%;
    position: relative;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    z-index: 1;
}

.price-item:hover, .best-price {
    box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
    z-index: 2;
}

.best-price {
    top: -30px;
    transform: scale(1.05);
}

.price-item:hover {
    z-index: 3;
}

.price-head {
    float: left;
    width: 100%;
    padding: 22px 20px;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.best-price .price-head {
    background: #5ECFB1;
}
.error-form {
    text-align: left;
    color:#EC8500;
}

.price-head h3 {
    color: #fff;
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.71);
    display: inline-table;
    padding: 10px 30px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.21);
    overflow: hidden;
}

.price-num {
    padding: 30px 15px 20px;
}

.price-num span.curen {
    position: relative;
    top: -32px;
    padding-right: 7px;
    font-weight: 400;
    font-size: 17px;
}

.price-num-desc {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    color: #bcbcbc;
    display: block;
    margin-top: 6px;
}

.price-num-item {
    font-size: 65px;
    position: relative;
    font-weight: 600;
}

.price-desc {
    padding: 20px 0 50px;
}

.price-desc ul li {
    float: left;
    width: 100%;
    padding: 18px 0;
    color: #666;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #eee;
}

.price-desc ul li:nth-child(2n+1) {
    background: #fbfbfb;
}

.price-desc ul li:first-child {
    border-top: 1px solid #eee;
}

.price-link {
    display: inline-table;
    padding: 15px 20px;
    color: #fff;
    border-radius: 30px;
    margin-top: 30px;
    font-weight: 500;
}

.price-content {
    background: #fff;
    border: 1px solid #eee;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.recomm-price {
    margin-top: 40px;
    font-weight: 600;
    color: #ADC7DD;
}

.recomm-price i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #5ECFB1;
    font-size: 16px;
    border-radius: 100%;
    border: 1px solid #5ECFB1;
    margin-bottom: 10px;
}

.pricing-wrap {
    margin: 30px 0;
}

/*------Testimonials ------------------------------------------------*/
.testimonials-carousel.single-carousel .slick-slide-item {
    margin: 0 10px;
}

.testimonilas-avatar-item {
    position: relative;
    display: inline-table;
    z-index: 10;
}

.testimonilas-avatar-item:before {
    top: -30px;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    display: none;
}

.testimonials-carousel .slick-current .testimonilas-avatar-item:before {
    display: block;
}

.testimonilas-avatar-item:before {
    border-color: transparent;
    border-top-color: #4DB7FE;
    border-width: 14px;
    margin-left: -14px;
}

.testimonilas-avatar-item h4 {
    font-weight: 700;
    color: #1A1F3E;
    font-size: 16px;
    padding: 10px 0;
}

.testimonilas-avatar-item span {
    color: #566985;
    font-weight: 500;
}

.testimonilas-avatar {
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    display: inline-block;
}

.testimonilas-avatar img {
    width: 80px;
    height: 80px;
}

.testimonilas-text {
    padding: 65px 50px;
    width: 50%;
    overflow: hidden;
    z-index: 1;
    position: relative;
    border-radius: 10px;
    background: #1A1F3E;
    margin: 30px auto;
    border: 1px solid #eee;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.testimonials-carousel .slick-current .testimonilas-text {
    border-color: transparent;
}

.testimonilas-text:before, .testimonilas-text:after {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    color: #ccc;
    opacity: 0.2;
    font-size: 50px;
}

.testimonilas-text:before {
    content: "\f10e";
    top: 20px;
    left: 20px;
}

.testimonilas-text:after {
    content: "\f10d";
    bottom: 20px;
    right: 20px;
}

.testimonilas-text p {
    font-size: 14px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    color: #fff;
}

.testimonials-carousel .slick-current .testimonilas-text:before, .testimonials-carousel .slick-current .testimonilas-text:after {
    opacity: 0.6;
    color: #fff;
}

.testimonials-carousel .slick-current .testimonilas-text p {
    color: #fff;
}

.testimonilas-text .listing-rating {
    margin-bottom: 20px;
}

.testimonilas-text .listing-rating i {
    margin: 0 5px;
}

/*------Sponsors------------------------------------------------*/
.sp-cont {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    z-index: 20;
    margin-top: -20px;
    cursor: pointer;
    background: #fff;
    border-radius: 100%;
}

.sp-cont:hover {
    color: #fff;
}

.sp-cont.sp-cont-next {
    right: 0;
}

.sp-cont.sp-cont-prev {
    left: 0;
}

.logo-customer{
    margin-top:30px;
}

.spons-list li {
    float: left;
    width: 20%;
    padding: 0 20px;
}

.spons-list li img {
    width: 100%;
    height: auto;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.spons-list li img:hover {
    opacity: 0.6;
}

.small-paddinsec {
    padding: 70px 0;
}

/*------ footer------------------------------------------------*/
footer.main-footer {
    float: left;
    width: 100%;
    position: relative;
    padding: 60px 0 0 0;
    z-index: 50;
    overflow: hidden;
}

footer.main-footer.fixed-footer {
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
}

.height-emulator {
    z-index: 1;
}

.dark-footer {
    background: #1A1F3E;
}

.light-footer {
    background: #fff;
}

.footer-widget h3 {
    font-size: 17px;
    float: left;
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.71);
    font-weight: 900;
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
    padding-bottom: 25px;
}

.sub-footer {
    margin-top: 40px;
    background: #1A1F3E;
    padding: 28px 0;
}

.copyright {
    display: inline-block;
    text-align: center;
    color: rgba(255, 255, 255, 0.61);
    position: relative;
    font-size: 13px;
    position: relative;
    top: 6px;
    font-weight: 500;
}

.footer-social {
    float: right;
}

.footer-social li {
    float: left;
    margin-left: 23px;
}

.footer-social li a {
    float: left;
    border-left: none;
    font-size: 16px;
}

.footer-social li a:hover {
    color: #999;
}

.footer-menu {
    margin-top: 20px;
}

.footer-menu li {
    float: left;
    text-align: left;
    padding-right: 16px;
    position: relative;
}

.footer-menu li:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    width: 4px;
    height: 4px;
    right: 6px;
    border-radius: 100%;
}

.footer-menu li:last-child:before {
    display: none;
}

.footer-menu li a {
    color: #eee;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: 'AvantGarde', 'Helvetica', 'Arial', sans-serif;
}

.footer-menu li a:hover {
    color: rgba(255, 255, 255, 0.41);
}

#footer-twiit img, p.interact, #footer-twiit div.user {
    display: none;
}

#footer-twiit {
    text-align: left;
}

#footer-twiit a {
    color: #fff;
}

#footer-twiit p.tweet {
    font-size: 12px;
    color: #999;
    float: left;
    padding: 6px 12px;
    background: rgba(255, 255, 255, 0.11);
    border-radius: 6px;
    overflow: hidden;
    font-weight: 700;
    margin-bottom: 10px;
}

#footer-twiit .timePosted a {
    color: #ccc;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    position: relative;
    float: left;
    margin-bottom: 30px;
    padding-left: 20px;
}

#footer-twiit .timePosted a:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    content: "\f099";
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
    opacity: 0.7;
    font-size: 14px;
}

#footer-twiit .timePosted a:after {
    top: -10px;
    left: 22px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

#footer-twiit .timePosted a:after {
    border-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.11);
    border-width: 7px;
    margin-left: 7px;
}

.subscribe-widget p {
    color: rgba(255, 255, 255, 0.71);
    text-align: left;
}

.subscribe-widget {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.21);
    padding-bottom: 20px;
    margin-bottom: 10px;
}

.footer-widget #subscribe {
    float: left;
    width: 100%;
}

.footer-widget #subscribe .enteremail {
    float: left;
    width: 100%;
    background: #fff;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.21);
    margin-top: 20px;
}

.footer-widget #subscribe-button {
    float: right;
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: #fff;
    margin-top: 10px;
    font-weight: 600;
    cursor: pointer;
}

.footer-widget #subscribe-button:hover {
    color: rgba(255, 255, 255, 0.71);
}

.footer-widget #subscribe-button:hover i {
    color: rgba(255, 255, 255, 1.0);
}

.subscribe-message {
    color: #fff;
    float: left;
    margin-top: 10px;
    text-align: left;
    font-weight: 500;
    line-height: 19px;
}

.subscribe-message i {
    padding-right: 6px;
}

.subscribe-message.valid i {
    color: #5ECFB1;
}

.subscribe-message.error i {
    color: #F00;
}

.footer-widget #subscribe-button i {
    padding-right: 6px;
}

.sub-footer .about-widget img {
    float: left;
    width: 120px;
    height: auto;
}

.footer-widget .about-widget h4 {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    color: #bcbcbc;
    padding-top: 6px;
    font-size: 17px;
    text-align: left;
    width: 100%;
    float: left;
    margin-bottom: 12px;
}

.footer-widget .footer-contacts-widget p {
    color: #fff;
    text-align: left;
}

.footer-widget .about-widget p {
    border-bottom: 1px solid rgba(255, 255, 255, 0.21);
}

.footer-widget .about-widget .btn {
    float: left;
    margin-top: 20px;
    padding: 13px 20px;
    border-radius: 20px;
    color: #fff;
    font-weight: 600;
}

.footer-contacts {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px dashed rgba(255, 255, 255, 0.21);
}

.footer-contacts li {
    float: left;
    width: 100%;
    text-align: left;
    padding: 10px 0;
    font-size: 13px;
    font-weight: 500;
}

.footer-contacts li a, .footer-contacts li span {
    color: rgba(255, 255, 255, 0.51);
}

.footer-contacts li a {
    color: rgba(255, 255, 255, 0.81);
    padding-left: 4px;
}

.footer-contacts li i {
    padding-right: 4px;
}

.footer-widget .widget-posts li {
    padding: 13px 0;
    margin-bottom: 8px;
}

.footer-widget .widget-posts-img {
    float: left;
    width: 30%;
}

.footer-widget .widget-posts-img img {
    border-radius: 4px;
}

.footer-widget .widget-comments-img img {
    border-radius: 100%;
}

.footer-widget .widget-posts-descr {
    float: left;
    width: 70%;
    padding-left: 20px;
    text-align: left;
    position: relative;
}

.footer-widget .widget-posts-descr:before {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 20px;
    right: 0;
    height: 1px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.21);
}

.footer-widget .widget-posts-descr a {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.71);
    font-weight: 600;
    padding-bottom: 20px;
}

.footer-widget .widget-posts-descr a:hover {
    color: #fff;
}

.footer-widget .widget-posts-date {
    float: left;
    width: 100%;
    margin-top: 10px;
    color: #999;
    font-weight: 600;
    font-size: 12px;
}

.footer-widget .widget-posts-date i {
    color: rgba(255, 255, 255, 0.41) !important;
    padding-right: 10px;
}

.about-wrap {
    margin-bottom: 30px;
}

.about-wrap .list-single-main-item-title h3 {
    font-size: 24px;
}

.about-wrap p, .about-wrap h4 {
    text-align: left;
    color: #666;
}

.about-wrap h4 {
    font-size: 16px;
    padding: 10px 0 0;
    font-weight: 600;
    color: #ADC7DD;
}

.about-wrap .section-separator {
    margin-bottom: 5px;
}

/*------features------------------------------------------------*/
.features-box-container {
    margin-top: 30px;
}

.features-box {
    padding: 15px 20px 15px 100px;
    overflow: hidden;
    position: relative;
}

.features-box .time-line-icon {
    position: absolute;
    left: 10px;
    top: 20px;
    width: 70px;
    height: 70px;
}

.features-box .time-line-icon i {
    font-size: 40px;
}

.features-box h3 {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 15px;
}

.features-box p {
    text-align: left;
    color: #fff;
}

/*------Timeline------------------------------------------------*/
.process-wrap li {
    float: left;
    width: 33.3%;
    padding: 25px 40px;
    position: relative;
}

.process-item {
    float: left;
    width: 100%;
    position: relative;
    border: 1px solid #eee;
    padding: 40px 30px;
    border-radius: 6px;
    z-index: 20;
    background: #fff;
    overflow: hidden;
}

.process-wrap li i {
    margin: 0 auto 30px;
    font-size: 59px;
}

.process-wrap li h4 {
    text-align: center;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    color: #566985;
}

.process-wrap p {
    text-align: center;
    color: #999;
    font-size: 13px;
}

.process-count {
    position: absolute;
    z-index: 1;
    font-size: 84px;
    font-weight: bold;
    left: -20px;
    top: -10px;
    opacity: 0.1;
    color: #666;
}

.process-item:hover .process-count {
    opacity: 0.3;
}

.pr-dec {
    position: absolute;
    top: 50%;
    right: -50px;
    width: 150px;
    height: 1px;
    border-bottom: 1px solid #eee;
}

.process-end {
    display: inline-block;
    margin-top: 30px;
    position: relative;
}

.process-end:before {
    content: '';
    position: absolute;
    top: -60px;
    left: 50%;
    width: 1px;
    height: 70px;
    background: #eee;
    z-index: -1;
}

.process-end i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: #fff;
    border-radius: 100%;
    z-index: 3;
    background: #5ECFB1;
    border-radius: 100%;
    font-size: 20px;
    color: #fff;
    border-radius: 100%;
    animation: pulse 2.1s infinite ease-in-out;
}

.time-line-container {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 130px;
}

.time-line-container:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: -5px 0 0 -3px;
    z-index: 2;
}

.time-line-container:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1px;
    left: 50%;
    top: 50%;
    background: #eee;
    z-index: 1;
    margin-left: -50px
}

.time-line-container.lf-im:after {
    margin-left: 0
}

.tl-left {
    float: left;
}

.tl-right {
    float: right;
}

.time-line-box {
    width: 45%;
}

.time-line-box img, .video-box img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    border-radius: 10px;
}

.video-box img {
    border-radius: 0;
}

.time-line-wrap {
    padding: 110px 0 70px;
}

.time-line-wrap:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: 50%;
    top: 0;
    border-left: 2px dashed #eee;
}

.tl-text {
    padding: 35px 30px;
    border-radius: 6px;
    border: 1px solid #eee;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    background: #fff;
}

.time-line-icon {
    position: relative;
    margin-bottom: 30px;
}

.tl-text i {
    font-size: 65px;
    -webkit-box-reflect: below 1px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(.6, transparent), to(rgba(0, 0, 0, 0.2)));
}

.tl-text h3 {
    color: #BDCBDB;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
}

.step-item {
    position: absolute;
    top: -90px;
    left: 50%;
    width: 120px;
    padding: 13px 0;
    color: #fff;
    border-radius: 30px;
    margin-left: -60px;
    font-weight: 600;
    font-size: 12px;
}

.timeline-end {
    position: absolute;
    bottom: 70px;
    left: 50%;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: #fff;
    border-radius: 100%;
    z-index: 3;
    background: #5ECFB1;
    margin-left: -30px;
    border-radius: 100%;
    font-size: 20px;
    color: #fff;
    border-radius: 100%;
    animation: pulse 2.1s infinite ease-in-out;
}

/*------Join------------------------------------------------*/
.cirle-bg {
    position: absolute;
    right: 10%;
    width: 500px;
    height: 500px;
    bottom: -70%;
    z-index: 1;
    opacity: 0.1;
}

.join-wrap {
    padding: 10px 0 10px 30px;
    border-left: 1px solid rgba(255, 255, 255, 0.21)
}

.join-wrap h3 {
    float: left;
    width: 100%;
    text-align: left;
    font-size: 34px;
    font-weight: 700;
    padding-bottom: 20px;
    color: #fff;
}

.join-wrap p {
    text-align: left;
    color: rgba(255, 255, 255, 0.51);
    font-size: 13px;
}

.join-wrap-btn {
    display: inline-table;
    padding: 20px 55px;
    text-transform: uppercase;
    background: #fff;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 700;
    position: relative;
    top: 15px;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
}

.join-wrap-btn:hover, .trs-btn:hover, .search-widget .search-submit:hover, .cs-wrapper .subcribe-form #subscribe #subscribe-button:hover, .cs-social li a:hover {
    background: #2C3B5A;
    color: #fff;
}

.join-wrap-btn i {
    padding-left: 6px;
    font-size: 13px;
}

.video-box {
    overflow: hidden;
    border-radius: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.video-box-btn {
    position: absolute;
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin: -35px 0 0 -35px;
    background: #fff;
    color: #fff;
    z-index: 20;
    left: 50%;
    top: 50%;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.video-box-btn i {
    transform: scale(1.4);
}

.video-box-btn:hover {
    transform: scale(1.1);
}

.color-bg-text {
    padding-top: 70px;
    padding-left: 30px;
}

.color-bg-text h3 {
    font-size: 34px;
    text-align: left;
    padding-bottom: 20px;
    color: #fff;
    font-weight: 600;
}

.color-bg-text p {
    text-align: left;
    color: rgba(255, 255, 255, 0.81);
}

.color-bg-link {
    float: left;
    margin-top: 40px;
    padding: 20px 55px;
    text-transform: uppercase;
    background: #fff;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 700;
    position: relative;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
}

/*------Blog------------------------------------------------*/
.card-post {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
}

.card-post-img img {
    float: left;
    width: 100%;
    height: auto;
}

.card-post-content {
    padding: 15px 20px 0;
    border: 1px solid #eee;
}

.card-post-content h3 {
    float: left;
    width: 100%;
    text-align: left;
    color: #000;
    font-size: 19px;
    font-weight: 700;
    padding: 15px 0;
}

.card-post-content h3 a {
    color: #334e6f;
}

.card-post-content p {
    text-align: left;
}

.home-posts {
    margin-bottom: 40px;
}

.card-post-content .post-opt {
    border-top: 1px solid #eee;
    width: 100%;
    padding: 15px 0 25px;
    margin-top: 20px;
}

.post-nav {
    margin-top: 20px;
    margin-bottom: 10px;
}

.post-link {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: #999;
}

.post-link span {
    color: #334e6f;
    font-size: 14px;
}

.post-link i {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    line-height: 40px;
    font-size: 22px;
}

.post-link:hover i {
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
    -o-transform: rotateX(360deg);
    -ms-transform: rotateX(360deg);
    transform: rotateX(360deg);
}

.post-link.prev-post-link {
    float: left;
    padding-left: 40px;
    text-align: left;
}

.post-link.next-post-link {
    float: right;
    padding-right: 40px;
    text-align: right;
}

.post-link.prev-post-link i {
    left: 0;
    text-align: left;
}

.post-link.next-post-link i {
    right: 0;
    text-align: right;
}

.post-author {
    float: left;
    margin-right: 20px;
    margin-top: 10px;
}

.post-author img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    float: left;
    margin-right: 20px;
}

.post-author span {
    font-weight: 600;
    position: relative;
    top: 12px;
    color: #666;
}

.post-opt {
    padding-top: 24px;
}

.post-opt, .post-opt li {
    float: left;
}

.post-opt li {
    margin-right: 20px;
}

.post-opt li span, .post-opt li a {
    color: #999;
    font-weight: 500;
}

.post-opt li i {
    padding-right: 6px;
}

.jr-insta-thumb {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.jr-insta-thumb ul {
    list-style: none;
}

.jr-insta-thumb li {
    float: left;
    width: 33.3%;
    padding: 5px;
    overflow: hidden;
}

.jr-insta-thumb li img {
    width: 100%;
    height: auto;
    border-radius: 6px;
}

.jr-insta-thumb li a, .post-link i {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.jr-insta-thumb li a:hover {
    opacity: 0.6;
}

.metier-list li {
    color: #EC8500;
    font-size: 13px;
    text-align: left;
    margin-left: 50px;
    margin-bottom: 10px;
    font-weight: bold;
}

/*------ to-top------------------------------------------------*/
.to-top {
    position: fixed;
    bottom: 60px;
    right: 50px;
    width: 40px;
    height: 40px;
    color: #fff;
    line-height: 40px;
    font-size: 17px;
    background: #292929;
    z-index: 116;
    cursor: pointer;
    display: none;
    border-radius: 3px;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
    -webkit-transform: translate3d(0, 0, 0);
}

.to-top:hover, .color-bg-link:hover {
    background: #2C3B5A;
    color: #fff;
}

/*------ Video ------------------------------------------------*/
.media-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    line-height: 0;
    z-index: 1;
}

.video-container video {
    width: 100%;
}

.video-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden !important;
    top: 0;
    left: 0;
    padding: 0 !important;
}

.video-iframe iframe {
    position: absolute;
    top: -75px;
    left: 50%;
    width: 100%;
    height: 100%;
    display: block;
}

.media-container.video-parallax {
    height: 120%;
    top: -10%;
}

.resp-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
}

.resp-video iframe,
.resp-video object,
.resp-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.video-holder iframe {
    position: absolute;
    top: -75px;
    left: 50%;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
}

.video-item h3 {
    font-size: 34px;
    color: #fff;
    float: left;
    width: 100%;
    font-weight: 700;
    padding-bottom: 20px;
}

.video-item p {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    color: #eee;
    text-align: left;
    font-size: 13px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}

.video-item a {
    display: inline-table;
    margin-top: 30px;
}

/*------sahre------------------------------------------------*/
.share-holder.hid-share, .share-holder.hid-share .showshare {
    float: right;
}

.list-single-header-inside .share-holder.hid-share {
    margin-top: 20px;
}

.share-holder.hid-share {
    position: relative;
    margin-left: 30px;
}

.showshare {
    padding: 15px 80px 15px 40px;
    position: relative;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.showshare span {
    position: relative;
    z-index: 1;
    font-size: 11px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}

.showshare i {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    border-radius: 100%;
    font-size: 11px;
    z-index: 5;
    color: #666;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.showshare.vis-butsh {
    padding: 15px 40px 15px 80px;
}

.showshare.vis-butsh i {
    right: 69%;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

.showshare:hover i {
    color: #292929;
}

.visshare {
    visibility: visible;
    opacity: 1;
}

.share-holder.hid-share .share-container {
    position: absolute;
    top: -50px;
    right: 0;
    min-width: 170px;
    background: #2C3B5A;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
    padding: 8px 8px 8px 18px;
    margin-top: -30px;
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.share-holder.hid-share .share-container:after {
    top: 100%;
    right: 22px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #2C3B5A;
    border-width: 7px;
    margin-left: 7px;
    z-index: 2;
}

.vissc {
    opacity: 1;
}

.share-holder.hid-share .share-container .share-icon {
    float: left;
    width: 26px;
    height: 26px;
    line-height: 26px;
    position: relative;
    font-size: 13px;
    color: #fff;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.share-holder.hid-share .share-container .share-icon:hover {
    color: #fff;
}

.share-holder.hid-share .share-container.visshare, .bl-opt .share-holder.hid-share .share-container.visshare {
    top: -45px;
    opacity: 1;
    visibility: visible;
}

.share-icon:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}

.share-icon-digg:before {
    content: "\f1a6";
}

.share-icon-email:before {
    content: "\f003";
}

.share-icon-flickr:before {
    content: "\f16e";
}

.share-icon-foursquare:before {
    content: "\f180";
}

.share-icon-rss:before {
    content: "\f09e";
}

.share-icon-facebook:before {
    content: "\f09a";
}

.share-icon-twitter:before {
    content: "\f099";
}

.share-icon-tumblr:before {
    content: "\f173";
}

.share-icon-linkedin:before {
    content: "\f08c";
}

.share-icon-vimeo:before {
    content: "\f194";
}

.share-icon-instagram:before {
    content: "\f16d";
}

.share-icon-pinterest:before {
    content: "\f0d2";
}

.share-icon-googleplus:before {
    content: "\f0d5";
}

.share-icon-in1:before {
    content: "\f08c";
}

.share-icon-stumbleupon:before {
    content: "\f1a4";
}

/*------404------------------------------------------------*/
.error-wrap {
    padding: 40px 0;
}

.error-wrap h2 {
    font-size: 134px;
    color: #fff;
    font-weight: 900;
    font-family: 'AvantGarde', sans-serif;
    letter-spacing: 10px;
}

.error-wrap p {
    color: rgba(255, 255, 255, 0.91);
    font-size: 14px;
}

.error-wrap form {
    margin: 30px auto 20px;
    max-width: 550px;
    overflow: hidden;
    border-radius: 30px;
    position: relative;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.error-wrap form input {
    float: left;
    width: 100%;
    border: none;
    background: #fff;
    height: 50px;
    padding: 0 100px 0 20px;
    z-index: 1;
}

.error-wrap form .search-submit {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 50px;
    line-height: 50px;
    z-index: 2;
    color: #fff;
    border: none;
    cursor: pointer;
}

.error-wrap form .search-submit:hover {
    background: #2C3B5A;
}

.error-wrap a.btn {
    display: inline-table;
    margin-top: 10px;
}

#contact-form #message {
    float: left;
    width: 100%;
    padding-bottom: 20px;
    text-align: left;
}

/*------Coming Soon------------------------------------------------*/
.cs-wrapper {
    padding: 200px 50px 150px;
}

.cs-wrapper .countdown {
    margin: 30px 0;
}

.soon-title {
    color: #fff;
    font-size: 44px;
    font-weight: 800;
}

.cs-countdown-item {
    float: left;
    width: 25%;
    padding: 15px 10px;
    position: relative;
}

.cs-countdown-item:before {
    content: '';
    position: absolute;
    right: -3px;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background: #fff;
    top: 50%;
    margin-top: -3px;
}

.cs-countdown-item:last-child:before {
    display: none
}

.cs-countdown-item span {
    color: #fff;
    font-size: 74px;
    font-weight: 700;
    font-family: 'AvantGarde', sans-serif;
}

.cs-countdown-item p {
    color: rgba(255, 255, 255, 0.71);
    font-size: 16px;
}

.cs-social {
    margin-top: 50px;
}

.cs-social:before {
    content: '';
    position: absolute;
    top: -25px;
    left: 50%;
    height: 1px;
    width: 70px;
    margin-left: -35px;
    background: rgba(255, 255, 255, 0.21);
}

.cs-social li {
    display: inline-block;
    margin: 0 8px;
}

.cs-social li a {
    float: left;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    color: #fff;
    font-size: 14px;
}

.fixed-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0);
}

.cs-wrapper .subcribe-form form {
    max-width: 450px;
    margin: 15px auto;
    position: relative;
}

.cs-wrapper .subcribe-form p {
    color: #fff;
    font-size: 14px;
}

.cs-wrapper .subcribe-form #subscribe input {
    width: 100%;
    height: 48px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 30px;
    padding: 0 30px;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.cs-wrapper .subcribe-form #subscribe #subscribe-button {
    height: 38px;
    padding: 0 30px;
    border-radius: 30px;
    border: none;
    position: absolute;
    right: 6px;
    top: 6px;
    color: #fff;
    cursor: pointer;
}

.mob-bg {
    display: none;
}

/*------Responsive------------------------------------------------*/
@media only screen and  (max-width: 1524px) {
    .cs-wrapper {
        padding: 120px 50px 100px;
    }
}

@media only screen and  (max-width: 1324px) {
    .show-search-button {
        display: block;
    }

    .vis-header-search {
        display: none;
        position: absolute;
        left: 20px;
        top: 100px;
        border-radius: 30px;
        box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.2);
    }

    .slider-container-wrap .sw-btn {
        margin-top: 0;
        top: 10%;
    }

    .slider-container-wrap {
        padding: 250px 0 400px;
    }
}

@media only screen and  (max-width: 1064px) {
    .nav-button-wrap, .mob-bg {
        display: block;
    }

    .fixed-footer {
        position: relative !important;
        float: left;
    }

    .main-menu nav ul, video.bgvid, .height-emulator {
        display: none;
    }

    .main-menu {
        position: absolute;
        top: 80px;
        right: -20px;
        width: 300px;
        z-index: 50;
        padding: 15px 10px;
        background: #fff;
        border-bottom-left-radius: 6px;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }

    .vismobmenu {
        opacity: 1;
        right: 0;
        visibility: visible;
    }

    .menusb {
        display: block;
        float: left;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .main-menu .menusb ul {
        display: block;
        float: left;
        position: relative;
        width: 100%;
    }

    .menusb li {
        margin: 0;
        float: left;
        width: 100%;
        position: relative;
        overflow: hidden;
        padding: 0 20px;
    }

    .menusb li i {
        position: absolute;
        right: 18px;
        top: 12px;
    }

    .menusb a {
        float: left;
        width: 100%;
        padding: 8px 5px;
        position: relative;
        font-weight: 700;
        font-size: 14px;
        color: #566985;
        line-height: 20px;
        text-align: left;
    }

    .menusb a i {
        transform: rotate(-90deg);
    }

    .menusb a.back {
        padding-left: 24px;
    }

    .menusb a.back:before {
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        content: "\f177";
        font-size: 14px;
    }

    .process-wrap li {
        padding: 25px 20px;
    }

    .footer-img {
        max-width: 70%;
        height: auto;
    }

    .menu-img {
        max-width: 70%;
        height: auto;
        border-radius: 50%;
    }

    .footer-widget, .video-box, .user-profile-menu-wrap {
        margin-bottom: 20px;
    }

    .images-collage {
        margin-bottom: 70px;
    }

    .listing-item-grid .listing-item-cat h3 {
        font-size: 16px;
    }

    .about-wrap .list-single-main-item-title h3 {
        padding-top: 30px;
    }

    .team-box {
        width: 50%;
    }

    .join-wrap-btn {
        display: block;
        float: left;
    }

    .color-bg-text {
        padding-left: 0;
    }

    section.list-single-section {
        padding-top: 100px;
    }

    .list-single-header-column .viewed-counter, .list-single-header-column .custom-scroll-link {
        float: left;
    }

    .share-holder.hid-share {
        position: relative;
        margin-left: 0;
        float: left;
        margin-right: 30px;
    }

    .share-holder.hid-share .share-container {
        right: inherit;
        left: 0;
    }

    .share-holder.hid-share .share-container:after, .share-holder.hid-share .share-container:before {
        right: inherit;
        left: 20px;
    }

    .col-list-wrap {
        width: 100%;
    }

    .map-container.column-map {
        width: 100%;
        position: relative;
        float: left;
        height: 500px;
        top: 0;
    }

    .absolute-main-search-input {
        margin-left: -300px;
        width: 600px;
    }

    .edit-profile-photo {
        margin-top: 40px;
    }

    .edit-profile-photo img {
        width: 100px;
        float: left;
        margin-left: 30px;
    }

    .change-photo-btn {
        margin-top: 0;
    }

    .copyright {
        display: block;
        text-align: left;
        float: left;
        width: 100%;
        margin: 15px 0;
        top: 15px;
    }

    .card-post {
        margin-bottom: 20px;
    }

    .slider-container-wrap.fs-slider .hero-section-wrap {
        top: 15%;
        padding-top: 0;
    }

    .slider-container-wrap.fs-slider .sw-btn {
        margin-top: 0;
        top: 90%;
    }

    .main-search-input-item .nice-select {
        background: #fff;
    }
}

@media only screen and  (max-width: 768px) {
    .gallery-item {
        width: 50%;
    }

    .process-wrap li, .price-item, .time-line-box, .listing-item, .listsearch-input-item, .center-col-list-wrap .listing-item, .dashboard-listing-table-text, .dashboard-listing-table-image {
        width: 100%;
    }

    .listsearch-input-item, .box-cat, .dashboard-listing-table-image {
        margin-bottom: 10px;
    }

    .dashboard-listing-table-image {
        padding: 0;
    }

    .price-item, .tl-text {
        margin-bottom: 30px;
    }

    .best-price {
        top: 0;
        transform: scale(1.0);
    }

    .footer-social {
        float: left;
        margin-top: 15px;
    }

    .footer-social li {
        margin-left: 0;
        margin-right: 23px;
    }

    .hero-section .intro-item h2 {
        font-size: 44px;
    }

    .main-search-input {
        background: rgba(255, 255, 255, 0.2);
        padding: 14px 20px 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.0);
    }

    .main-search-input-item {
        width: 100%;
        border: 1px solid #eee;
        height: 50px;
        border: none;
        margin-bottom: 10px;
    }

    .main-search-input-item .selectbox {
        background: #fff;
        border-radius: 6px !important;
    }

    .main-search-input-item input {
        border-radius: 6px !important;
        background: #fff;
    }

    .main-search-button {
        position: relative;
        float: left;
        width: 100%;
        border-radius: 6px;
    }

    .listing-item-grid .listing-counter, .time-line-container:before, .time-line-container:after, .scroll-nav-wrapper, .cs-countdown-item:before, .show-search-button span, .show-reg-form:before, .listing-view-layout {
        display: none;
    }

    .single-facts .inline-facts-wrap, .cs-countdown-item, .statistic-item-wrap {
        width: 50%;
    }

    .cs-wrapper {
        padding: 100px 20px 50px;
    }

    .cs-countdown-item span {
        font-size: 54px;
    }

    .add-list {
        padding: 8px 16px;
        top: 26px;
        font-size: 10px;
    }

    .fs-header {
        padding: 0px 20px;
    }

    .reviews-comments-item {
        padding: 0 0 30px 0;
    }

    .review-comments-avatar {
        position: relative;
        left: 20px;
        float: left;
        margin-bottom: 20px;
    }

    .show-reg-form {
        margin-right: 20px;
    }

    .main-register-holder {
        padding: 0 20px;
    }

    .header-search-input-item input {
        width: 90px;
    }

    .vis-header-search {
        margin-left: 0;
    }

    .absolute-main-search-input {
        margin-left: -150px;
        width: 300px;
    }

    .header-user-menu {
        position: fixed;
        right: 0;
        top: 100px;
        z-index: 30;
    }

    .header-user-name {
        background: #2F3B59;
        padding: 15px 20px;
        border-radius: 6px;
    }

    .header-user-name span {
        top: 4px;
        left: -30px;
    }

    .header-user-name span img {
        width: 100%;
        height: 100%;
    }

    .header-user-name:before {
        bottom: 16px;
        right: -16px;
    }

    .box-cat {
        min-width: 80px;
        padding: 10px 0;
    }

    .logo-holder {
        height: 20px;
        top: 32px;
    }
}

@media only screen and  (max-width: 540px) {
    .gallery-item, .single-facts .inline-facts-wrap, .team-box, .cs-countdown-item, .list-single-header-contacts li, .list-single-facts .inline-facts-wrap, .listing-features li, .listing-item, .statistic-item-wrap {
        width: 100%;
    }

    .list-single-header-contacts li {
        text-align: left;
        margin-bottom: 10px;
    }

    .list-single-facts .inline-facts-wrap, .tags-stylwrap a {
        margin-bottom: 10px;
    }

    .hero-section .intro-item h2, .parallax-section .section-title h2, .soon-title, .list-single-header-item h2, .list-single-header-inside .list-single-header-item h2 {
        font-size: 34px;
    }

    .cs-social li {
        margin: 0 5px;
    }

    .error-wrap h2 {
        font-size: 94px;
    }

    .list-single-carousel-wrap {
        height: 300px;
    }

    .fw-carousel .slick-slide-item {
        height: 300px !important;
    }

    .list-single-facts .inline-facts-wrap:before {
        display: none;
    }

    .add-list {
        display: none;
    }

    header.main-header {
        padding: 0 10px;
    }

    .show-reg-form {
        margin-right: 5px;
    }

    .slider-container-wrap.fs-slider .hero-section-wrap {
        top: 15%;
        padding-top: 0;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .bg {
        background-attachment: scroll !important;
        -webkit-background-size: cover;
        background-position: center;
    }
}